import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notify = (message: string, type: 'info' | 'success' | 'error' | 'warning' = 'info') => {
    switch (type) {
        case 'success':
            toast.success(message);
            break;
        case 'error':
            toast.error(message);
            break;
        case 'warning':
            toast.warn(message);
            break;
        default:
            toast.info(message);
            break;
    }
};

export default notify;
