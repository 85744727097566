import React, { useEffect, useState } from "react";
import { getDoc, getDocs, collection } from "firebase/firestore";
import { db, auth } from "../firebase";
import {  Badge, Select, Table, TextInput, Title, Alert, Text, Divider, Flex, Loader, Paper } from "@mantine/core";
import { isToday, parseISO } from 'date-fns';
import {
  // create_Customer,
  // createCustomerProp,
  // createUser,
  delete_customer,
  deleteCustomerProp,
  // getAgents,
  getCustomers,
  // getLoans,
  handleExport,
//   request_loan,
  requestLoanProp,
  uploadCustomerData,
} from "../services/core-api";
import { getDomainFromEmail } from "../utils/getUserDomain";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Button } from "@mantine/core";
import InputText from "../components/InputText";
import { formatCurrency } from "../utils/currencyFormat";
import {
  IconTrash,
  IconEdit,
  IconFileExport,
  IconFileExcel,
  IconFileUpload,
  IconInfoCircle,
  IconList,
  IconInfoSmall,
  IconPlus,
  IconSearch,
  IconRefresh,
} from "@tabler/icons-react";
import { DeleteModal } from "../components/DeleteModal";
import { formatDate } from "../utils/formatDate";
import { generateAccountNumber } from "../utils/generateAccountNo";
import { ToastContainer } from 'react-toastify';
import notify from "../utils/notify";
import { useOrgInfo } from "../context/orgContext";
import { useLoanDataContext } from "../context/LoanContext";



export default function Loans() {
  // const [loans, setLoans] = useState<customerProp[]>([]);
  // const [customersAddedToday, setCustomersAddedToday] = useState<customerProp[]>([]);

  const [search, setSearch] = useState("");
  const [isloading, setIsloading] = useState(false);
  const userEmail = auth.currentUser?.email || "";
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [processDelete, setProcessDelete] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState("");
  const [opened, { open, close }] = useDisclosure(false);
  const [file, setFile] = useState(null);
  const [openUpload, setOpenUpload] = useState(false);
  const [fileName, setFileName] = useState("");
  const [savingsType, setSavingsType] = useState<string | null>("");
  const [gender, setGender] = useState<string | null>("");
  // const { companyName, generateAccNo } = useOrgInfo();
  const [updateCustomerState, setUpdateCustomerState] = useState(false)
  const {loans, customersAddedToday, setCustomersAddedToday, setLoans, loading, refreshLoans} = useLoanDataContext()

  const [form, setForm] = useState({
    fullName: "",
    phone: "",
    address: "",
    nationalId: "",
    accountNumber: "",
    loanAmount: "",
    interestRate: ""
  });

  const initialFormState = {
    fullName: "",
    phone: "",
    address: "",
    nationalId: "",
    accountNumber: "",
    loanAmount: "",
    interestRate: ""
  };

  // const getCustomClaims = async (): Promise<string> => {
  //   const user = auth.currentUser;
  //   if (user) {
  //     const idTokenResult = await user.getIdTokenResult(true);
  //     const customClaims = idTokenResult.claims;
  //     return `${customClaims.agentId}`;
  //   }
  //   return "";
  // };

  const totalLoanBalance =
  loans?.reduce((dept, customer) => dept + (customer.loanAmount - parseFloat(customer.accountBalance)), 0)

  const handleOnchange = (event: any) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

//   const requestLoan = async () => {
//     if (
//       form.accountNumber === "" ||
//       form.loanAmount === "" ||
//       form.interestRate === "" 
//     ){
//       return;

//     }
//     setIsloading(true);
//     try {
//       const newLoan: requestLoanProp = {
//         accountNumber: form.accountNumber,
//         loanAmount: form.loanAmount,
//         interestRete: form.interestRate,
//         agentEmail: auth.currentUser?.email || "",
//         agentName: auth.currentUser?.displayName || "",
//         agentID: await getCustomClaims()
//       };

//       const response = await request_loan(newLoan);
//       if (response) {
//         setIsloading(false);
//         getLoanData();
//         close();
//        notify(response)
//         setForm(initialFormState);
//       }
//     } catch (err) {
//       console.log(err);
//       setIsloading(false);
//     }
//   };


 
 
  
  function openDeleteModal(accountNumber: string) {
    setDeleteOpen(true);
    setAccountToDelete(accountNumber);
  }

  const deleteCustomer = async (accountNumber: string) => {
    setProcessDelete(true);
    const deleteCustomerData: deleteCustomerProp = {
      agentEmail: userEmail,
      accountNumber: accountNumber,
    };
    try {
      const response = await delete_customer(deleteCustomerData);
      notify(response.message);
      refreshLoans();
      setDeleteOpen(false);
      setProcessDelete(false);
    } catch (err) {
      console.log(err);
      setDeleteOpen(false);
      setProcessDelete(false);
    }
    return;
  };

//   const updateCustomer = (customer: any)=> {
//     setUpdateCustomerState(true)
//     open()
//     const udpateCustomerData = {
//       fullName: customer.fullName,
//       depositAmount: customer.depositAmount,
//       accountNumber: customer.accountNumber,
//       phone: customer.phone,
//       agentEmail: userEmail,
//       address: customer.address,
//       DOB: customer.DOB,
//       gender: customer.gender,
//       savingsType: savingsType,
//       nationalId: customer.nationalId,
//     }
//       setForm(udpateCustomerData);    
//   }
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRow = (index: any) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const rows = loans?.map((customer, index) => (
    <React.Fragment key={index}>
      <Table.Tr onClick={() => toggleRow(index)} style={{ cursor: 'pointer' }}>
      <Table.Td onClick={() => toggleRow(index)} style={{ whiteSpace: 'nowrap', cursor: 'pointer',  overflow: 'hidden', textOverflow: 'ellipsis' }}> <IconPlus size={16} /></Table.Td>
        <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{customer.nationalId}</Table.Td>
        {/* <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{formatDate(customer.createdAt)}</Table.Td> */}
        <Table.Td style={{ backgroundColor: customer.date === new Date().toDateString() ? '#c5ebc5' : '', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{customer.fullName}</Table.Td>
        {/* <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{customer.address}</Table.Td> */}
        {/* <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{customer.DOB}</Table.Td> */}
        {/* <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{customer.gender}</Table.Td> */}
        {/* <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{customer.phone}</Table.Td> */}
        <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{customer.accountNumber}</Table.Td>
        <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: parseFloat(customer.accountBalance) >= 20 ? 'green' : 'red', fontWeight: 'bolder' }}>
          {`${formatCurrency(parseFloat(customer.accountBalance), 'GHS')}`}
        </Table.Td>
        <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold' }}>{formatCurrency(customer.loanAmount, 'GHS')}</Table.Td>
        <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold' }}>{ customer.loanAmount === parseFloat(customer.accountBalance) || customer.loanAmount < parseFloat(customer.accountBalance) ? formatCurrency(0, 'GHS') : formatCurrency(customer.loanAmount - parseFloat(customer.accountBalance), 'GHS')}</Table.Td> {/**outstanding customer loan balance */}

        {/* <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{customer.savingsType}</Table.Td> */}
        {/* <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{customer.agentName}</Table.Td>
        <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{customer.agentEmail}</Table.Td>
        <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{customer.agentID}</Table.Td> */}
        <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Badge size="sm" color={customer.approved !== true ? 'yellow' : 'green'}>
            {customer.approved !== true ? 'Pending' : 'Approved'}
          </Badge>{' '}
        </Table.Td>
        <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Badge size="sm" color={parseFloat(customer.accountBalance) < customer.loanAmount ? 'red' : 'green'}>{parseFloat(customer.accountBalance) < customer.loanAmount ? 'Not complete': 'Paid'}</Badge>
            </Table.Td>
        <Table.Td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <IconTrash
            style={{ cursor: 'pointer' }}
            onClick={() => openDeleteModal(customer.accountNumber)}
            size={20}
            color="red"
          />
        </Table.Td>
      </Table.Tr>

      {/* Collapsible details row */}
      {expandedRow === index && (
        <Table.Tr>
          <Table.Td colSpan={14}>
            <div style={{ padding: '30px', backgroundColor: '#f9f9f9'}}>
              <Title order={5} mb={10}> Details:</Title>
   
              <div style={{ display: 'flex', textAlign: 'left'}}>
             <p><strong>Loan Amount:</strong></p>  <p style={{ marginLeft: 5}}> {formatCurrency(customer.loanAmount, 'GHS')}</p>
              </div>

              <div style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left'}}>
              <p style={{ textAlign: 'left'}}><strong>Interest Rate:</strong></p> <p style={{ marginLeft: 5}}> {customer.interestRate}%</p>
              </div>


              <div style={{ display: 'flex'}}>
              <strong>Address:</strong> <p style={{ marginLeft: 5}}> {customer.address}</p>
              </div>

              <div style={{ display: 'flex'}}>
              <strong>Date of birth:</strong> <p style={{ marginLeft: 5}}>{customer.DOB}</p>
              </div>

  {/**next row */}
  <div style={{ display: 'flex'}}>
             <strong>Gender:</strong>  <p style={{ marginLeft: 5}}> {customer.gender}</p>
              </div>

              <div style={{ display: 'flex'}}>
              <strong>Phone number:</strong> <p style={{ marginLeft: 5}}> {customer.phone}</p>
              </div>


              <div style={{ display: 'flex'}}>
              <strong>Account type:</strong> <p style={{ marginLeft: 5}}> {customer.savingsType}</p>
              </div>

              <div style={{ display: 'flex'}}>
              <strong>Agent Name:</strong> <p style={{ marginLeft: 5}}>{customer.agentName}</p>
              </div>
   

             {/**next row */}
       
              <div style={{ display: 'flex'}}>
             <strong>Agent Email:</strong>  <p style={{ marginLeft: 5}}> {customer.agentEmail}</p>
              </div>

              <div style={{ display: 'flex'}}>
              <strong>Agent ID:</strong> <p style={{ marginLeft: 5}}> {customer.agentID}</p>
              </div>


              <div style={{ display: 'flex'}}>
              <strong>Date created:</strong> <p style={{ marginLeft: 5}}> {formatDate(customer.createdAt)}</p>
              </div>
      

              </div>
             
           


              {/* Add more details as needed */}
          
          </Table.Td>
        </Table.Tr>
      )}
    </React.Fragment>
  ));

  // useEffect(() => {
  //   getLoanData();
  // }, [form]);

  function openModal() {
    open();
  }

  const filterData = () => {
    const filtered = loans?.filter((client) =>
      client.fullName.includes(search.charAt(0).toUpperCase().trim())
    );
    setLoans(filtered);
  };

  const downloadData = () => {
    if(loans.length <= 0) {
      notify("No data to export")
      return
    }
    setIsloading(true);
    handleExport(loans)
      .then(() => {
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  };
  const handleFileChange = (e: any) => {
    setFile(e.currentTarget.files[0]);
    setFileName(e.currentTarget.files[0].name);
  };

  // const uploadData = () => {
  //   setIsloading(true);
  //   const customersFile = {
  //     file: file,
  //     email: auth.currentUser?.email ? auth.currentUser.email : "",
  //   };
  //   uploadCustomerData(customersFile)
  //     .then((response: any) => {
  //       if (response.status === 200) {
  //         notify("Customer data uploaded successfully");
  //         setOpenUpload(false);
  //         refreshLoans();
  //       } else {
  //         notify("Error uploading data");
  //         setOpenUpload(false);
  //       }
  //       setIsloading(false);
  //     })
  //     .catch((err: any) => {
  //       setIsloading(false);
  //       return err;
  //     });
  // };

  return (
    <div>
       <Modal
        size={"lg"}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        opened={opened}
        onClose={close}
        title={
          <Title ml={10} order={5} >
            {" "}
           Customer Loan 
          </Title>
        }
        centered
      >
        <div style={{ display: "flex", justifyContent: "space-around" }}>

         <TextInput
            error={form.accountNumber === ""}
            w={"45%"}
            label="Account Number"
            mb={10}
            value={form.accountNumber}
            name="accountNumber"
            onChange={handleOnchange}
            placeholder="Account Number"
          />
           <TextInput
            disabled
            error={form.fullName === ""}
            w={"45%"}
            label="Full Name"
            mb={10}
            value={form.fullName}
            name="fullName"
            onChange={handleOnchange}
            placeholder="Full Name"
          />
        </div>
  

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <TextInput
            error={form.phone === ""}
            w={"45%"}
            label="Phone number"
            mb={10}
            value={form.phone}
            name="phone"
            type="number"
            onChange={handleOnchange}
            placeholder="Phone number"
          />

          <TextInput
            error={form.address === ""}
            w={"45%"}
            label="Address"
            mb={10}
            value={form.address}
            name="address"
            onChange={handleOnchange}
            placeholder="Address"
          />
        </div>

        <hr style={{ margin: 30 }} />

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Select
            error={savingsType === ""}
            placeholder="Package"
            label="Package Type"
            w={"45%"}
            defaultValue="Monthly package"
            data={["Monthly package", "Yearly package", "Loan"]}
            value={savingsType}
            onChange={setSavingsType}
          />

          <TextInput
            error={form.nationalId === ""}
            w={"45%"}
            label="National ID"
            mb={10}
            value={form.nationalId}
            name="nationalId"
            onChange={handleOnchange}
            placeholder="National ID"
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 20,
          }}
        >
          <Button
            w={"45%"}
            variant="outline"
            color="#000"
            onClick={() => close()}
          >
            Cancel
          </Button>
       <Button
            w={"45%"}
            variant="filled"
            color="#000"
            onClick={() =>  {}}
          >
            {isloading ? "Please wait..." : "Confirm"}
          </Button> 
        </div>
      </Modal>
      <DeleteModal
        title={<Title order={5}>Delete Customer</Title>}
        isLoading={processDelete}
        proceed={() => deleteCustomer(accountToDelete)}
        onClose={() => setDeleteOpen(false)}
        opened={deleteOpen}
        content={`Are you sure you want to delete account ${accountToDelete}`}
      />

     <Paper p={20}> 
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Title order={4}> Loans </Title>   
          <div style={{ display: 'flex', alignItems: 'center'}}>
          <Title mr={10}>{customersAddedToday.length}</Title><small>Recorded today</small>
          <div style={{ backgroundColor: '#c5ebc5', width: 20, height: 20, marginLeft: 20, marginRight: 5}}></div>
          <small> New Loan</small>
          </div>
        </div>

        <div style={{ marginRight: 20}}>
         <Title mb={5} order={6} style={{ fontWeight: 'normaL'}}> Total Outstanding Loan Balance</Title>
         <Title mb={10} order={2}> {formatCurrency(totalLoanBalance, 'GHS')}</Title>
         </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 20,
          width: '100%',
        }}
      >
        <div>
          <Button
            disabled={isloading}
            variant="outline"
            mr={10}
            color="#000"
            onClick={downloadData}
          >
            {/* onClick={openModal} */}
            <IconFileExcel /> &nbsp; Export Data
          </Button>

          <Button
          leftSection={loading? <Loader size={16} color="#fff" /> : <IconRefresh size={16} />}
            disabled={loading}
            variant="solid"
            mr={10}
            bg="#000"
            onClick={refreshLoans}
          >
            {/* onClick={openModal} */}
          Refresh
          </Button>

        </div>

        <div style={{ display: "flex"}}>
          <InputText
          iconLeft={<IconSearch />}
            width="100%"
            value={search}
            type="text"
            name="search"
            onChange={(e: any) => setSearch(e.target.value)}
            placeholder="Enter customer name"
          />

          <Button
          className="removeOnMobile"
            variant="default"
            color="#274941"
            onClick={filterData}
            style={{ marginInline: 10, width: '100%' }}
          >
            {" "}
            Filter by name
          </Button>
 
       
          {/* <Button
           className="removeOnMobile"
            variant="solid"
            bg="#000"
            onClick={() => openModal()}
            style={{ marginInline: 10, width: '100%' }}
          >
            {" "}
            Record Loan
          </Button> */}
        </div>
      </div>

      <div style={{ overflowX: "auto", overflowY: "auto", maxHeight: "100%" }}>
        <Table highlightOnHover stripedColor='#f2f2f2'  highlightOnHoverColor='#f4f3ef' stickyHeader stickyHeaderOffset={10} striped mt={20}>
          <Table.Thead style={{ backgroundColor: "#fafafa" }}>
            <Table.Tr>
            <Table.Th><IconInfoSmall />  </Table.Th>
              <Table.Th style={{ fontWeight: 'bold'}}>National ID</Table.Th>
              {/* <Table.Th>Created</Table.Th> */}
              <Table.Th style={{ fontWeight: 'bold'}}> Name</Table.Th>
              <Table.Th style={{ fontWeight: 'bold'}}>Acc Number</Table.Th>
              <Table.Th style={{ fontWeight: 'bold'}}>Account Balance</Table.Th>
              <Table.Th style={{ fontWeight: 'bold'}}>Loan Amount</Table.Th>
              <Table.Th style={{ fontWeight: 'bold'}}>Outstanding balance</Table.Th>
              {/* <Table.Th>Acc Type</Table.Th> */}
              {/* <Table.Th>Agent Name</Table.Th>
              <Table.Th>Agent Email</Table.Th>
              <Table.Th>Agent ID</Table.Th> */}
              <Table.Th style={{ fontWeight: 'bold'}}>Account Status</Table.Th>
              <Table.Th style={{ fontWeight: 'bold'}}>Loan Status</Table.Th>
              <Table.Th style={{ fontWeight: 'bold'}}>Action</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </div>
      </Paper>
    </div>
  
  );
}
