import { Link } from "react-router-dom";
import LandingLayout from "../layouts/landing";
import { ArrowLeftIcon } from "lucide-react";

const NotFound = () => {
  return (
    <LandingLayout>
      <div className="bg-white">
        <div className="container mx-auto">
          <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-9xl font-bold animate-bounce">404</h1>
            <p className="text-xl mb-10">Page not found</p>
            <Link
              to="/"
              className="bg-green-500 text-white px-6 py-3 rounded-3xl flex gap-2 items-center"
            >
              <ArrowLeftIcon />
              Go back
            </Link>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default NotFound;
