import React, {useEffect, useState} from 'react'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { auth } from '../firebase';
import { useNavigate } from "react-router-dom";
import { ROUTES } from '../routes/const';
import Card from '../components/card';
import { Button, Paper, Table, Text, Title, RingProgress, Loader, Badge } from '@mantine/core';
import { IconArrowDown, IconArrowUp, IconArrowUpRight, IconPlus, IconRefresh } from '@tabler/icons-react';
import { formatCurrency } from '../utils/currencyFormat';
import { totalDailyBalance } from '../services/core-api';
import { getDocs, collection, query, orderBy, limit, where } from 'firebase/firestore';
import { getDomainFromEmail } from '../utils/getUserDomain';
import { db } from '../firebase'
import { formatDate } from '../utils/formatDate';
import { AreaChart, LineChart } from '@mantine/charts';
// import useAdminStatus from '../hooks/useAdminStatus';
// import useUserClaims from '../hooks/useUserClaims';
import { useOrgInfo } from '../context/orgContext';
import { useAdminStatusContext } from '../context/adminContext';
import { useDailyTotalBalanceContext } from '../context/TotalDailyTransactionAmountContext';
import { useRecentTransactionsContext } from '../context/recentTransactionsContext';
import { useDailyTransactionsCountContext } from '../context/TotalDailyTransactionCount';
import { useCustomerDataContext } from '../context/customersContext';
import { useWeeklyMetricsContext } from '../context/weeklyTransactionMetricsContext';
const isProd = process.env.NODE_ENV === 'production'


export default function Home() {
const { transactions, loadingData, readRecentTransactionsData } = useRecentTransactionsContext();
const { refreshDailyTransactions} = useDailyTransactionsCountContext()
const {dailyTotal, refreshDailyTotal} = useDailyTotalBalanceContext()
const {refreshCustomers} = useCustomerDataContext()
const {refresh} = useOrgInfo()
const weeklyMetricsData = useWeeklyMetricsContext()


const rows = transactions?.map((trans) => (
  <Table.Tr key={trans.id}>
      <Table.Td>{trans.transId}</Table.Td>
      <Table.Td>{formatDate(trans.createdAt)}</Table.Td>
      <Table.Td style={{ color: 'green', fontWeight: 'bold'}}>{trans.transactionType}</Table.Td>
      <Table.Td>{trans.accountNumber}</Table.Td>
    <Table.Td>{trans.customer}</Table.Td>
    <Table.Td>{formatCurrency(parseFloat(trans.amount), 'GHS')}</Table.Td>
    <Table.Td>{trans.agentName}</Table.Td>
    {/* <Table.Td>{trans.agentID}</Table.Td>
    <Table.Td>{trans.agentEmail}</Table.Td> */}
    <Table.Td>{trans.approved ? <Badge variant='outline' color='green' style={{ fontWeight: 'bold'}}>Successful</Badge> : <Badge variant='outline' color='orange' style={{ fontWeight: 'bold'}}>Pending</Badge>}</Table.Td>
  </Table.Tr>
));

  return (
      <div style={{ margin: '0 auto'}}>
        <div className='dailyTransactionsSection' style={{ marginInline: 50, borderRadius: 10, marginBottom: 20, display: 'flex', justifyContent:'space-between', alignItems: 'center'}}>
      <div>
        <Text style={{color: '#000', fontSize: 12}}>Total Daily Transaction</Text>
      <Title c={"#000"} order={1} mt={5} style={{ fontSize: 40}}> {formatCurrency(dailyTotal?.total_daily_transactions, 'GHS')}  <Badge mr={5} color='green' leftSection={<IconArrowDown size={12} />}><span style={{ fontWeight: 'bolder'}}>{formatCurrency(dailyTotal.total_deposits, 'GHS')}</span> Deposit</Badge> 
      <Badge leftSection={<IconArrowUpRight size={12} />} color='orange'> <span style={{ fontWeight: 'bolder'}}>{formatCurrency(dailyTotal.total_withdrawals, 'GHS')} </span> Withdrawal</Badge> </Title>
        <Text fw={'bolder'} style={{ fontSize: 12}} c="#008000" mt={5}>As at {new Date().toLocaleString()}</Text>
      </div>

<div style={{ display: 'flex', alignItems: 'center'}}>
      {loadingData ? <Loader  style={{ cursor: 'pointer'}} size={20} color='#000' ml={10} /> :  <IconRefresh style={{ cursor: 'pointer', marginLeft: 10}} onClick={()=> {
         readRecentTransactionsData()
         refreshDailyTotal()
         refreshDailyTransactions()
         refreshCustomers()
         refresh()
      }} /> }
      <Title className='removeOnMobile' ml={5} mr={10} order={6}>Refresh </Title>
</div>
    
       {/* {isProd ? null : <Button disabled w={150} variant='filled' color='#e0ea52' c={"#000"}><IconPlus /> &nbsp;Deposit</Button>}   */}
      </div>

       <Card />

       <Paper className='recentTransactionsTable' mb={10} withBorder p={20}>
        <Title mb={10} order={5}>Weekly Transaction Count Metrics</Title>
       <LineChart
       yAxisLabel='Transaction Count'
       xAxisLabel='Transaction Date'
       withLegend
      h={350}
      data={weeklyMetricsData}
      dataKey="date"
      series={[
        { name: 'deposits', label: 'Deposit', color: 'green.6' },
        { name: 'withdrawals', label: 'Withdrawal', color: 'orange.6' },
      ]}
      curveType="linear"
    />
       </Paper>

       <Paper withBorder className='recentTransactionsTable'  p={20} h={'100%'} style={{ borderRadius: 10, }}>
       <Title order={4} mb={20}>Recent transactions</Title>

       <div style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '100%' }}>
  <Table striped stripedColor='#f2f2f2' highlightOnHover highlightOnHoverColor='#f4f3ef'>
    <Table.Thead style={{ backgroundColor: '#fafafa'}}>
      <Table.Tr>
      <Table.Th style={{ fontWeight: 'bold'}}>TID</Table.Th>
        <Table.Th style={{ fontWeight: 'bold'}}>Date</Table.Th>
        <Table.Th style={{ fontWeight: 'bold'}}>Transaction Type</Table.Th>
        <Table.Th style={{ fontWeight: 'bold'}}>Account number</Table.Th>
        <Table.Th style={{ fontWeight: 'bold'}}>Customer</Table.Th>
        <Table.Th style={{ fontWeight: 'bold'}}>Amount</Table.Th>
        <Table.Th style={{ fontWeight: 'bold'}}>Agent</Table.Th>
        <Table.Th style={{ fontWeight: 'bold'}}>Status</Table.Th>
      </Table.Tr>
    </Table.Thead>
    <Table.Tbody>{rows}</Table.Tbody>
  </Table>
</div>

       </Paper>
        </div>
  )
}
