import { Menu, Button, rem, Text, Avatar, Badge, Title } from '@mantine/core';
import { IconBell, IconBellCancel, IconLogout2, IconMessage, IconSearch, IconSettings } from '@tabler/icons-react';
import { signOut } from 'firebase/auth'
import { auth } from '../firebase';
import { useNavigate } from "react-router-dom";
import { ROUTES } from '../routes/const';
import { useEffect, useState } from 'react';
import defaultProfile from '../assets/defaultProfile.png'
// import useAdminStatus from '../hooks/useAdminStatus';
import useCollaboratorStatus from '../hooks/useCollaboratorStatus';
// import useTier from '../hooks/useTier';
import { useUser } from '../context/UserContext';
import { useAdminStatusContext } from '../context/adminContext';
import { useOrgInfo } from '../context/orgContext';

export default function UserMenu() {
  const navigate = useNavigate()
  const {isAdmin} = useAdminStatusContext()
  const isCollaborator = useCollaboratorStatus()
  // const plan = useTier()
  // const { plan } = useOrgInfo()
 const { setUser} = useUser()

  const handleLogout = async () => {
    try {
      await signOut(auth).then(()=> {
        setUser(null)
      navigate(ROUTES.login);
      })
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
   <>
   {/* <Badge color='orange' variant='light' mr={10}>{plan}</Badge> */}
   <Menu>
      <Menu.Target>
      <IconBell color='#000' style={{ marginInline: 10, cursor: 'pointer'}} />
      </Menu.Target>
      <Menu.Dropdown w={400} h={500} style={{ width: '100%', height: '100%', alignItems: 'center', display:'flex', justifyContent: 'center', textAlign: 'center', border: '1px solid #dedede'}} p={20}>
      <div style={{ display: 'flex', justifyContent: 'center'}}>
      <IconBellCancel />
       <Title ml={10} order={4}> No Notifications</Title>
       </div>
       {/* <Menu.Item>
       <div>
       <Title order={4}><IconBellCancel /> No Notifications</Title>
       </div>
        </Menu.Item> */}

        {/* Other items ... */}
      </Menu.Dropdown>
    </Menu>

    {/* <IconSettings color='#000' style={{ marginInline: 10, cursor: 'pointer'}} /> */}


 {/* <IconMessage color='#000' style={{ marginRight: 10}} /> */}

<Menu width={200} withArrow>
      <Menu.Target>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', cursor: 'pointer'}}>
      <Avatar color='#036559' src={auth.currentUser?.photoURL || defaultProfile} size={35} style={{ cursor: 'pointer'}} /> 
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginInline: 10, lineHeight: 1}}>
        <Text c={"black"} fw={'bolder'}>{auth.currentUser?.displayName}</Text>
      <small style={{ color: '#8c8c8c'}}>{isAdmin ? "Administrator": isCollaborator ? 'Collaborator' : "Staff"}</small>
      </div>
         </div>
      
      </Menu.Target>

      <Menu.Dropdown>
      {/* <Menu.Item leftSection={<IconSettings style={{ width: rem(14), height: rem(14) }} />}>
          Profile
        </Menu.Item> */}
        <Menu.Item color='red' onClick={()=> handleLogout()} leftSection={<IconLogout2 style={{ width: rem(14), height: rem(14), color: 'red' }} />}>
        Sign Out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
   </>
  );
}