import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { getAgents } from '../services/core-api';

// Define a context type for Admin status

interface customClaimsProp {
  admin: string;
  agentId: string;
  orgId: string;
  role: string;
}

interface AgentsProviderProp {
  agents: userType[];
  numberOfAdmins: number;
  refresh: ()=> void,
  setAgents: React.Dispatch<React.SetStateAction<userType[]>>;
  loading: boolean
}

export interface userType {
  displayName: string;
  email: string;
  id: string;
  phoneNumber: string;
  role: string;
  customClaims: customClaimsProp;
  orgId: string;
  emailVerified: boolean;
  photoURL: string;
  disabled: boolean;
}

// Create the context with an initial value of null
const AgentsContext = createContext<AgentsProviderProp | null>(null);

// Provider component
export const AgentsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [agents, setAgents] = useState<userType[]>([]);
  const [numberOfAdmins, setNumberOfAdmins] = useState<number>(0);
  const [loading, setLoading] = useState(false)
  const user = auth.currentUser?.email || '';

  const calculateNumberOfAdmins = (agentsList: userType[]) => {
    const adminCount = agentsList.filter((user) => user.customClaims.admin).length;
    setNumberOfAdmins(adminCount);
  };
  const fetchUsers = async () => {
    setLoading(true)
    try {
      const data = await getAgents(user);
      if (data) {
        setAgents(data);
        calculateNumberOfAdmins(data);
      }
    } catch (err) {
      // console.log(err);
      setAgents([]);
      setNumberOfAdmins(0);
    } finally{
    setLoading(false)
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [user]);

  return (
    <AgentsContext.Provider value={{ agents, numberOfAdmins, setAgents, loading, refresh: fetchUsers }}>
      {children}
    </AgentsContext.Provider>
  );
};

// Custom hook to consume the AgentsContext
export const useAgentsContext = () => {
  const context = useContext(AgentsContext);
  if (!context) {
    throw new Error('useAgentsContext must be used within an AgentsProvider');
  }
  return context;
};
