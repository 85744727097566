import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { weeklyMetrics } from '../services/core-api';

// Define a context type for Weekly Metrics
interface WeeklyMetrics {
  date: string;
  deposits: number;
  withdrawals: number;
}

// Create the context with an initial value of an empty array
const WeeklyTransactionMetricsContext = createContext<WeeklyMetrics[]>([]);

// Provider component
export const WeeklyMetricsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [weeklyMetricsData, setWeeklyMetricsData] = useState<WeeklyMetrics[]>([]);

  useEffect(() => {
    const getWeeklyMetricsData = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        try {
          const data = await weeklyMetrics(currentUser.email || '');
          setWeeklyMetricsData(data); // Update the state with the fetched data
        } catch (error) {
          console.error('Error fetching metrics:', error);
        }
      }
    };

    getWeeklyMetricsData();
  }, []);

  return (
    <WeeklyTransactionMetricsContext.Provider value={weeklyMetricsData}>
      {children}
    </WeeklyTransactionMetricsContext.Provider>
  );
};

// Custom hook to consume the Weekly Metrics context
export const useWeeklyMetricsContext = () => {
  const context = useContext(WeeklyTransactionMetricsContext);
  if (!context) {
    throw new Error('useWeeklyMetricsContext must be used within a WeeklyMetricsProvider');
  }
  return context;
};
