

import {  Text } from '@mantine/core'
import { useOrgInfo } from '../context/orgContext'
import Logo from '../assets/applogo1.svg'

export default function CompanyLogo() {
    const { companyName } = useOrgInfo()
  return (
    //removeOnMobile
    <div className="" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10}}>
    <img src={Logo} width={30} />
    <Text style={{ fontSize: 16, fontWeight: 700, color: "#fff", marginLeft: 10 }}>
      { companyName || 'DigiCollect'}
    </Text>
    </div>
  )
}
