import React, { createContext, useContext, useState, useEffect, ReactElement } from 'react';
import { auth, db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import useAdminOrgId from '../hooks/useAdminOrgId';


interface OrgInfoProp {
  id: any;
  companyName: string;
  companyNumber: string;
  orgId: string;
  sms: {purchased: number, balance: number};
  generateAccNo: boolean;
  plan: string
  customerAccountTypes: string[]
}

interface OrgInfoContextType {
  companyName: string;
  companyNumber: string;
  orgId: string;
  sms: {purchased: number, balance: number};
  generateAccNo: boolean;
    plan: string;
    customerAccountTypes: string[]
  refresh: () => void;
}

const OrgInfoContext = createContext<OrgInfoContextType | null>(null);

export const OrgInfoProvider: React.FC<{ children: React.ReactNode }> = ({ children }: any) => {
  const [orgInfo, setOrgInfo] = useState<OrgInfoProp | null>(null);
  const [customerAccountTypes, setCustomerAccountTypes] = useState<string[]>([])
  const organizationId = useAdminOrgId();

  const getOrgInfo = async () => {
    if (!organizationId) return;

    try {
      const q = query(collection(db, 'orgInfo'), where('orgId', '==', organizationId));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0]; // Assuming you want the first document only
        const orgInformation: OrgInfoProp = {
          id: doc.id,
          ...doc.data(),
        } as OrgInfoProp;
        setOrgInfo(orgInformation);
        setCustomerAccountTypes(orgInformation.customerAccountTypes)
      } else {
        console.warn('No organization found for the given orgId');
        setOrgInfo(null);
      }
    } catch (error: any) {
      console.error('Error getting orgInfo:', error);
      alert('Error getting org info');
      setOrgInfo(null);
    }
  };

  useEffect(() => {
    getOrgInfo();
  }, [organizationId]);

  return (
    <OrgInfoContext.Provider
      value={{
        companyName: orgInfo?.companyName || '',
        companyNumber: orgInfo?.companyNumber || '',
        orgId: orgInfo?.orgId || '',
        sms: { purchased: orgInfo?.sms.purchased || 0, balance: orgInfo?.sms.balance || 0 },
        plan: orgInfo?.plan || 'Trial',
        customerAccountTypes: orgInfo?.customerAccountTypes || customerAccountTypes,
        generateAccNo: orgInfo?.generateAccNo || false,
        refresh: getOrgInfo,
      }}
    >
      {children}
    </OrgInfoContext.Provider>
  );
};

export const useOrgInfo = () => {
  const context = useContext(OrgInfoContext);
  if (!context) {
    throw new Error('useOrgInfo must be used within an OrgInfoProvider');
  }
  return context;
};
