import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase'; // Ensure this path is correct
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust this import according to your project structure

interface Transaction {
    id: string
    customer: string
    amount: string,
    accountNumber: string
    date: string,
    agentName: string,
    agentID: string,
    transactionsMethod: string
    agentEmail: string
    phone: string
    createdAt: any
    approved: boolean
    transactionType: string
}

// Define the context type
interface TransactionDataContextProps {
    depositTransactions: Transaction[]; // List of deposit transactions
    withdrawalTransactions: Transaction[]; // List of withdrawal transactions
    setDTransactions: React.Dispatch<React.SetStateAction<Transaction[]>>;
    setWTransactions: React.Dispatch<React.SetStateAction<Transaction[]>>;
    loading: boolean; // Loading state
    refreshTransactions: () => void; // Function to refresh transactions data
}

// Create the context
const TransactionDataContext = createContext<TransactionDataContextProps | null>(null);

// Provider component
export const TransactionDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [depositTransactions, setDepositTransactions] = useState<Transaction[]>([]);
    const [withdrawalTransactions, setWithdrawalTransactions] = useState<Transaction[]>([]);
    const [loading, setLoading] = useState<boolean>(false); // Loading state

    const readTransactionsData = async () => {
        const user = auth.currentUser;
        if (user) {
            setLoading(true); // Start loading
            try {
                const idTokenResult = await user.getIdTokenResult();
                const claims = idTokenResult.claims;

                if (claims.admin) {
                    // Query for Deposit transactions
                    const depositQuery = query(
                        collection(db, 'transactions'),
                        where('orgId', '==', claims.orgId),
                        where('transactionType', '==', 'Deposit'),
                        orderBy('createdAt', 'desc')
                    );
                    const depositSnapshot = await getDocs(depositQuery);
                    const depositData = depositSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Transaction));

                    // Query for Withdrawal transactions
                    const withdrawalQuery = query(
                        collection(db, 'transactions'),
                        where('orgId', '==', claims.orgId),
                        where('transactionType', '==', 'Withdrawal'),
                        orderBy('createdAt', 'desc')
                    );
                    const withdrawalSnapshot = await getDocs(withdrawalQuery);
                    const withdrawalData = withdrawalSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Transaction));

                    setDepositTransactions(depositData); // Store the deposit transactions
                    setWithdrawalTransactions(withdrawalData); // Store the withdrawal transactions
                } else {
                    // console.log('User does not have admin privileges');
                }
            } catch (error) {
                console.error('Error getting custom claims or reading transactions:', error);
                setDepositTransactions([]); // Reset transactions on error
                setWithdrawalTransactions([]);
            } finally {
                setLoading(false); // Stop loading
            }
        } else {
            // console.log('No user is signed in');
            setDepositTransactions([]);
            setWithdrawalTransactions([]);
        }
    };

    // Fetch transactions data on mount
    useEffect(() => {
        readTransactionsData();
    }, []);

    return (
        <TransactionDataContext.Provider value={{ depositTransactions, withdrawalTransactions, setDTransactions: setDepositTransactions, setWTransactions: setWithdrawalTransactions, loading, refreshTransactions: readTransactionsData }}>
            {children}
        </TransactionDataContext.Provider>
    );
};

// Custom hook to use the TransactionDataContext
export const useTransactionDataContext = () => {
    const context = useContext(TransactionDataContext);
    if (!context) {
        throw new Error('useTransactionDataContext must be used within a TransactionDataProvider');
    }
    return context;
};
