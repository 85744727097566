import { Text } from '@mantine/core'
import { IconEye } from '@tabler/icons-react'
import React, { ReactElement } from 'react'

interface inputType {
    placeholder: string
    type: string
    value: string
    name: string
    onChange: any
    width: string
    mb?: number
    mt?: number
    disabled?: boolean
    label?: string
    height?: number
    icon?: ReactElement
    iconLeft?: ReactElement
}
export default function InputText( { iconLeft, icon, disabled, height, mt, label, placeholder, type, name, value, onChange, width, mb}: inputType) {
  return (
    <>
  <Text style={{marginTop: mt, fontWeight: 'bold', fontSize: 14}}>{label} </Text>
    <div className='inputText' style={{ width: width, fontSize: 14, marginBottom: mb, paddingInline:10, height: height }}>
    <span style={{ color: '#dedede'}}>{ iconLeft ? iconLeft: null}</span>
    <input disabled={disabled} type={type} name={name} value={value} onChange={onChange} placeholder={placeholder} style={{ width: '100%',backgroundColor: 'transparent', marginInline: 10}}/>
    { icon ?icon: null}
    </div>
    </>
  )
}
