import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase'; // Ensure this path is correct
import { getPendingWithdrawals } from '../services/core-api'; // Adjust import path as needed

interface Withdrawal{
    id: string
   customer: string
   accountNumber: string
   agentEmail: string
   agentID: string
   agentName: string
   amount: string
   approved: boolean
   balance: string
   createdAt: any
  phone: string
  transactionType: string
  date: string
  }
  

// Define the context type
interface WithdrawalApprovalContextProps {
    pendingWithdrawals: Withdrawal[]; // List of pending withdrawals
    setPendingWithdrawals: React.Dispatch<React.SetStateAction<Withdrawal[]>>;
    loading: boolean; // Loading state
    refreshWithdrawalApprovals: () => void; // Function to refresh pending withdrawals
}

// Create the context
const WithdrawalApprovalContext = createContext<WithdrawalApprovalContextProps | null>(null);

// Provider component
export const WithdrawalApprovalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [pendingWithdrawals, setPendingWithdrawals] = useState<Withdrawal[]>([]);
    const [loading, setLoading] = useState<boolean>(false); // Loading state

    const getWithdrawalApprovalData = async () => {
        const email = auth.currentUser?.email;
        if (email) {
            setLoading(true); // Start loading
            try {
                const withdrawalsData = await getPendingWithdrawals(email);
                setPendingWithdrawals(withdrawalsData);
            } catch (error) {
                console.error('Error fetching pending withdrawals:', error);
                // Handle errors accordingly, e.g., set empty state
                setPendingWithdrawals([]);
            } finally {
                setLoading(false); // Stop loading
            }
        } else {
            console.log('No user is signed in');
            setPendingWithdrawals([]);
        }
    };

    // Fetch pending withdrawals data on mount
    useEffect(() => {
        getWithdrawalApprovalData();
    }, []);

    return (
        <WithdrawalApprovalContext.Provider value={{ pendingWithdrawals, setPendingWithdrawals, loading, refreshWithdrawalApprovals: getWithdrawalApprovalData }}>
            {children}
        </WithdrawalApprovalContext.Provider>
    );
};

// Custom hook to use the WithdrawalApprovalContext
export const useWithdrawalApprovalContext = () => {
    const context = useContext(WithdrawalApprovalContext);
    if (!context) {
        throw new Error('useWithdrawalApprovalContext must be used within a WithdrawalApprovalProvider');
    }
    return context;
};
