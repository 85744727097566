import LandingLayout from "../layouts/landing";
import LandingHero from "../components/LandingHero";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "lucide-react";
import marketWoman from "../assets/marketWoman.png";
import AppleStoreImage from "../assets/apple-store.svg";
import GooglePlayImage from "../assets/google-play.svg";
import AppImage from "../assets/app.png";
import manOnPhone from '../assets/manOnPhone.svg'
import { IconBrandSpeedtest, IconBrandTelegram, IconDeviceDesktopAnalytics, IconHeartRateMonitor, IconLock, IconRocket, IconSeeding, IconSpeedboat, IconTrack } from "@tabler/icons-react";
import PaystackLogo from '../assets/thirdParty/paystack.png'
import MnotifyLogo from '../assets/thirdParty/mnotify.webp'
import { Text } from "@mantine/core";


const LandingHome = () => {
  return (
    <LandingLayout>
      <LandingHero />
      {/* <div style={{ padding: 20, backgroundColor: '#f8f8f8', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
  <Text className="Hero"> Trusted third-party services </Text>
  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
    <img src={PaystackLogo} style={{ width: '140px', height: 'auto', marginRight: 10 }} />
    <img src={MnotifyLogo} style={{ width: '140px', height: 'auto', marginLeft: 10 }} />
  </div>
</div> */}

      {/* benefits section */}
      <section className="py-32 bg-black">
        <article className="container mx-auto flex flex-col lg:flex-row px-3 gap-x-5 items-center">
          <div className="w-full lg:w-6/12 mb-20">
            {/* <a href="https://www.freepik.com/free-vector/finance-financial-performance-concept-illustration_3200373.htm#fromView=search&page=1&position=6&uuid=62966d48-ba15-45de-8bf8-78bbec16271f">
              Image by rawpixel.com on Freepik
            </a> */}
            <div className="flex">
              <div className="lg:w-10/12">
                <img src={marketWoman} alt="" loading="lazy" />
              </div>
              <div></div>
            </div>
          </div>

          <div className="w-full lg:w-6/12">
            <h4 className="text-3xl Hero text-white font-bold mb-10">
              Why choose DigiCollect
            </h4>

            <ul className="text-gray-500 mb-14 ">
              <li className="grid mb-10 items-start grid-flow-col justify-start">
                <div className="w-12 h-12 bg-green-500 text-white rounded-full flex items-center justify-center mr-5">
                  <IconBrandTelegram />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-green-500 word Hero">
                  Increased Efficiency
                  </h3>
                  <p className="text-md text-white Hero">
                 Our mobile app allow field agents to record transactions in real-time, eliminating the delay and potential errors associated with manual data entry.
                  </p>
                </div>
              </li>
              <li className="grid mb-10 items-start grid-flow-col justify-start">
                <div className="w-12 h-12 bg-green-500 text-white rounded-full flex items-center justify-center mr-5">
                  <IconDeviceDesktopAnalytics />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-green-500 Hero">
                     Monitoring Dashboard
                  </h3>
                  <p className="text-md text-white Hero">
                    Our intuitive admin dashboard 
                    track and manage clients savings and loans activities.
                  </p>
                </div>
              </li>
              <li className="grid mb-10 items-start grid-flow-col justify-start">
                <div className="w-12 h-12 bg-green-500 text-white rounded-full flex items-center justify-center mr-5">
                  <IconBrandSpeedtest />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-green-500 Hero">
                    Fast and reliable 24/7 data access.
                  </h3>
                  <p className="text-md text-white Hero">
                    You can access your data from anywhere at anytime without
                    any downtime.
                  </p>
                </div>
              </li>

              <li className="grid mb-10 items-start grid-flow-col justify-start">
                <div className="w-12 h-12 bg-green-500 text-white rounded-full flex items-center justify-center mr-5">
                 <IconRocket />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-green-500 Hero">
                  Boost Efficiency, Increase Transactions
                  </h3>
                  <p className="text-md text-white Hero">
                  Our mobile app equips agents to process transactions more quickly, leading to an increased number of transactions.
                  </p>
                </div>
              </li>

              <li className="grid mb-10 items-start grid-flow-col justify-start">
                <div className="w-12 h-12 bg-green-500 text-white rounded-full flex items-center justify-center mr-5">
                  <IconTrack />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-green-500 Hero">
                  Better Accountability
                  </h3>
                  <p className="text-md text-white Hero">
                  With a digital record-keeping system, every transaction can be tracked, making it easier to hold agents accountable.
                  </p>
                </div>
              </li>



              <li className="grid mb-10 items-start grid-flow-col justify-start">
                <div className="w-12 h-12 bg-green-500 text-white rounded-full flex items-center justify-center mr-5">
                  <IconLock />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-green-500 Hero">
                  Data Security
                  </h3>
                  <p className="text-md text-white Hero">
                   With our cloud based infrastructure, we ensure the safety of your financial records and making it easier to retrieve past transactions.
                  </p>
                </div>
              </li>
            </ul>

            {/* <Link
              to="/register"
              className="text-xl text-green-500 flex ml-2 items-center"
            >
              Get Started <ArrowRightIcon size={24} className="ml-2" />
            </Link> */}
          </div>
        </article>
      </section>
      {/* end benefits section */}

      {/* platform section */}
      <section className="py-20 platformSection">
        <article className="container mx-auto flex flex-col lg:flex-row items-center px-3">
          <div className="w-full lg:w-6/12 ">
            <h2 className="text-4xl font-bold mb-10 Hero">
              Download DigiCollect app
            </h2>

            <p className="text-lg text-gray-500 font-medium mb-0 Hero">
               Android mobile app for field agents (Also available on portal)
            </p>

            <div>
              <div className="w-full flex gap-1">
                {/* <Link to="/register" className="flex-0.5">
                  <img
                    src={AppleStoreImage}
                    alt=""
                    height={100}
                    width={140}
                    loading="lazy"
                  />
                </Link>
                */}
                <Link to="#" className="flex-1">
                  <img
                    src={GooglePlayImage}
                    alt=""
                    width={'35%'}
                    loading="lazy"
                  />
                </Link> 
              </div>
            </div>
          </div>

          <div className="w-full lg:w-6/12 md:w-6/12 mb-20 ">
            {/* <a href="https://www.freepik.com/free-photo/african-american-woman-using-smartphone-against-blue-background_21129920.htm#fromView=search&page=1&position=50&uuid=225f771f-7036-4621-8354-e3bfcfe324fc">Image by fabrikasimf on Freepik</a> */}
            <img src={manOnPhone} alt="" loading="lazy" />
          </div>
        </article>
      </section>
      {/* end platform section */}
    </LandingLayout>
  );
};

export default LandingHome;
