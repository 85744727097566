import { useEffect } from "react";
import LandingFooter from "../components/LandingFooter";
import "../styles/landing.css";

const LandingLayout = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    // set scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="body">
        {children}

        <LandingFooter />
      </div>
    </>
  );
};

export default LandingLayout;
