import { Link } from "react-router-dom";
import LandingNav from "../components/LandingNav";
import LandingLayout from "../layouts/landing";
import { ArrowRight } from "lucide-react";
import PhoneFrme from "../assets/phoneFrame.png";
import PhoneFrme1 from "../assets/phoneFrame1.png";

const Features = () => {
  return (
    <LandingLayout>
      <LandingNav inView={true} />

      <div className="mt-[72px] bg-white scroll-smooth">
        <header className="container mx-auto px-3 pt-10">
          <h1 className="font-bold text-4xl mb-10 Hero"> Features</h1>
        </header>
        <main className="text-lg">
          {/* <div className="container mx-auto px-3">
            <section className="bg-gray-200 p-5 rounded-lg shadow-sm mb-36">
              <h4 className=" text-gray-500 mb-5 text-lg">
                Digicollect has 2 platforms which are;
              </h4>
              <ul className="text-gray-500">
                <li className=" flex items-center  gap-x-1 mb-2 underline underline-offset-2">
                  <ArrowRight className="text-green-500" />
                  <a href="#dashboard">Dashboard Platform</a>
                </li>
                <li className="flex items-center  gap-x-1 mb-2 underline underline-offset-2">
                  <ArrowRight className="text-green-500" />
                  <a href="#mobile">Mobile App Platform</a>
                </li>
              </ul>
            </section>
          </div> */}

          {/* desktop */}
          <section
            id="dashboard"
            className="mb-20 bg-gradient-to-b lg:bg-gradient-to-l  from-yellow-100 lg:from-50% via-green-100 to-50%"
          >
            <article className="flex flex-col lg:flex-row justify-between px-3 container mx-auto lg:gap-x-5 items-center">
              <div className="lg:w-1/2  min-h-[500px] lg:order-2">
                <div className="py-10 px-3 lg:p-20 animate-fadeIn">
                  {/* <img src={PhoneFrme} alt="" loading="lazy" /> */}
                </div>
              </div>
              <div className="lg:w-1/2  py-10">
                <div className="">
                  <h2 className="text-3xl font-bold mb-5 Hero">
                     Dashboard portal features.
                  </h2>

                  <p className="text-gray-500 mb-10 Hero">
                    The dashboard contains many features and tools to help
                    over-see all activities in the organisation.
                  </p>

                  <small className="font-semibold mb-5 block Hero">FEATURES</small>

                  <ul className="text-lg text-gray-500">
                  <li className="grid grid-flow-col gap-x-1 justify-start items-start mb-3 Hero">
                      <ArrowRight className="text-green-500" /> Dashboard Overview
                    </li>
                    <li className="grid grid-flow-col gap-x-1 justify-start items-start mb-3 Hero">
                      <ArrowRight className="text-green-500" /> Track all transactions including savings
                      and loans.
                    </li>
                    <li className="grid grid-flow-col gap-x-1 justify-start items-start mb-3 Hero">
                      <ArrowRight className="text-green-500" />
                      Add users with user privileges
                    </li>
                    <li className="grid grid-flow-col gap-x-1 justify-start items-start mb-3 Hero">
                      <ArrowRight className="text-green-500" /> Register new
                      customers
                    </li>
                    <li className="grid grid-flow-col gap-x-1 justify-start items-start mb-3 Hero">
                      <ArrowRight className="text-green-500" /> Track Daily Collection Balance
                    </li>
                    <li className="grid grid-flow-col gap-x-1 justify-start items-start mb-3 Hero">
                      <ArrowRight className="text-green-500" /> Track Customer Balance
                    </li>
                    <li className="grid grid-flow-col gap-x-1 justify-start items-start mb-3 Hero">
                      <ArrowRight className="text-green-500" /> Track withdrawals
                    </li>
                    <li className="grid grid-flow-col gap-x-1 justify-start items-start mb-3 Hero">
                      <ArrowRight className="text-green-500" /> Export Data to XLSX and CSV
                    </li>
                    <li className="grid grid-flow-col gap-x-1 justify-start items-start mb-3 Hero">
                      <ArrowRight className="text-green-500" /> 24/7 uptime
                    </li>
                  </ul>
                </div>
              </div>
            </article>
          </section>

          {/* mobile */}
          <section
            id="mobile"
            className="bg-gradient-to-b lg:bg-gradient-to-r from-black lg:from-50% via-white to-50%  ..."
          >
            <article className="flex flex-col lg:flex-row justify-between px-3 container mx-auto items-center">
              <div className="lg:w-1/2  min-h-[500px]">
                <div className=" mx-auto py-10 px-3 lg:p-20 animate-fadeIn">
                  {/* <img src={PhoneFrme1} alt="" loading="lazy" /> */}
                </div>
              </div>
              <div className="lg:w-1/2 py-10 lg:pl-10 lg:pr-0">
                <div className="">
                  <h2 className="text-3xl font-bold mb-5 Hero">
                    Mobile app features.
                  </h2>

                  <p className="text-gray-500 mb-10 Hero">
                    This is made for agent of the micro-finance organization to
                    collect savings and loan from customers.
                  </p>

                  <small className="font-semibold mb-5 block Hero">FEATURES</small>

                  <ul className="text-lg text-gray-500">
                    <li className="grid grid-flow-col gap-x-1 justify-start items-start mb-3 Hero">
                      <ArrowRight className="text-green-500" /> Process transactions (Deposit & Withdrawal)
                    </li>
                    <li className="grid grid-flow-col gap-x-1 justify-start items-start mb-3 Hero">
                      <ArrowRight className="text-green-500" /> Register new customers
                    </li>
                    <li className="grid grid-flow-col gap-x-1 justify-start items-start mb-3 Hero">
                      <ArrowRight className="text-green-500" /> Automatically send transaction receipts via SMS to customers.
                    </li>
                    <li className="grid grid-flow-col gap-x-1 justify-start items-start mb-3 Hero">
                      <ArrowRight className="text-green-500" /> Track agent transactions including daily transactions
                    </li>
                    <li className="grid grid-flow-col gap-x-1 justify-start items-start mb-3 Hero">
                      <ArrowRight className="text-green-500" /> Easy and fast processing transaction
                    </li>
                  </ul>
                </div>
              </div>
            </article>
          </section>
        </main>
      </div>
    </LandingLayout>
  );
};

export default Features;
