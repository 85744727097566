import LandingNav from "./LandingNav";
import { ArrowRight } from "lucide-react";
import { ROUTES } from "../routes/const";
import { useInView } from "react-intersection-observer";
import { TextInput } from "@mantine/core";
import { IconMessage } from "@tabler/icons-react";
import { useUser } from "../context/UserContext";
import DashboardImage from '../assets/dashboard2.png'

// const LandingHero = () => {
//   // This is a custom hook that listens to the scroll event and changes the navbar color
//   useLayoutEffect(() => {
//     let options = {
//       root: null,
//       rootMargin: "0px",
//       threshold: 1.0,
//     };

//     let observer = new IntersectionObserver((entries) => {
//       entries.forEach((entry) => {
//         const navElement = document.querySelector("#landing-nav");
//         const navContainerElement = document.querySelector(
//           "#landing-nav .container"
//         );
//         const navBtnElement = document.querySelector("#landing-nav .btn");

//         if (entry.intersectionRatio === 1) {
//           navElement?.classList.remove("bg-white", "text-black");
//           navElement?.classList.add("lg:bg-transparent", "lg:relative");
//           navContainerElement?.classList.add("lg:px-0");
//         } else {
//           // styles for when the element is not in view
//           navElement?.classList.remove("lg:bg-transparent", "lg:relative");
//           navElement?.classList.add("bg-white", "text-black");
//           navContainerElement?.classList.remove("lg:px-0");
//         }
//       });
//     }, options);

//     let target = document.querySelector("#landing-hero");

//     if (target) observer.observe(target);

//     return () => {
//       if (target) observer.unobserve(target);
//     };
//   });

//   return (
//     <header className="hero max-h-screen" id="landing-hero">
//       <div className="overlay py-10 lg:py-0">
//         <div className="container mx-auto px-3">
//           <LandingNav />

//           <div className="flex items-center content">
//             <div className="lg:w-7/12">
//               <h1 className="text-5xl md:text-6xl font-extrabold md:font-bold text-white mb-7 animate-fadeIn leading-tight sm:leading-none">
//                 Collect and manage your{" "}
//                 <span className="text-yellow-500">micro-finance</span> data with
//                 ease.
//               </h1>
//               <p className="text-white text-xl mb-7 animate-slide">
//                 DigiCollect is a micro-finance management platform that allows
//                 you to collect and manage your customers data in safe, fast and
//                 reliable way.
//               </p>
//               <a href={ROUTES.login} className="mb-5">
//                 <button className="btn bg-green-500 text-white px-10 py-3 rounded-full hover:bg-white hover:text-green-500 transition ease-out duration-300 flex items-center">
//                   Get Started <ArrowRight size={20} className="ml-2" />
//                 </button>
//               </a>
//             </div>
//             <div className="lg:w-5/12"></div>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// };

const LandingHero = () => {
  // Use object destructuring, so you don't need to remember the exact order
  const { ref, entry } = useInView({
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  });
  const { user } = useUser()

  return (
    <header className="hero max-h-screen" id="landing-hero" ref={ref}>
    <div className="overlay py-10 lg:py-0">
      <div className="container mx-auto px-3">
        <LandingNav inView={entry?.intersectionRatio === 1 ? false : true} />
  
        <div className={`flex flex-col lg:flex-row items-center content ${entry?.intersectionRatio === 1 ? "" : "lg:mt-20"}`}>
          
          <div className="lg:w-8/12 mx-auto text-left mt-20 ">
          <h1 className="text-3xl font-extrabold md:text-5xl lg:text-6xl text-black mb-5 animate-fadeIn leading-tight sm:leading-none Hero">
  Eliminate {" "}
  <span className="text-yellow-500 font-extrabold">Paperwork </span>
  For Enhanced Microfinance Accountability
</h1>

            <p className="text-black text-base sm:text-lg mb-10 animate-slide Hero">
              Transform your microfinance operations by digitizing processes, ensuring greater transparency, and increasing efficiency without the clutter of paperwork.
            </p>
            {/* <p className="text-black text-sm mb-5 animate-slide Hero">
              Currently serving over <strong>500 unbanked Africans</strong>.
            </p> */}
            <div style={{ marginTop: 10, marginBottom: 20}}>
              <p className="Hero" style={{ fontWeight: 'bold', color: '#000'}}>Note:</p>
              <em className="text-black text-sm mb-5 animate-slide Hero">
              This app does not facilitate actual payments. It is designed for tracking and managing your transaction activities.
              </em>
              </div>
            
            <a href={ROUTES.login} className="mb-5 flex justify-left" style={{ alignItems: 'center' }}>
              <button className="btn bg-black text-white px-8 sm:px-10 py-3 rounded-full hover:bg-black hover:text-yellow-500 transition ease-out duration-300 flex items-center Hero">
                {user?.uid ? 'Dashboard' : 'Get Started'}
                <ArrowRight size={15} className="ml-2" />
              </button>
            </a>
          </div>
  
          <img src={DashboardImage} loading="lazy" className="w-full lg:w-1/2 mt-5 lg:mt-0" alt="Dashboard" />
        </div>
      </div>
    </div>
  </header>
  
  );
};

export default LandingHero;
