import LandingLayout from "../layouts/landing";
import LandingNav from "../components/LandingNav";
import { Link } from "react-router-dom";
import Caleb from '../assets/Team/Caleb-texa-profile-picture.jpeg'
import Evans from '../assets/Team/Evans.jpg'
import kaiden from '../assets/Team/cobby.jpg'
import Rauf from '../assets/Team/rauf.jpg'
import Emma from '../assets/Team/Emma.jpeg'





const landingAbout = () => {
  return (
    <LandingLayout>
      <LandingNav inView={true} />
      <main className="mt-[72px] bg-white">
        <div className="container mx-auto py-5 lg:py-10 px-3">
          <header className="mb-20">
            <h1 className="text-3xl font-bold mb-5 mt-20 Hero">About Us.</h1>
            <p className="text-lg text-gray-500">
              <span className="text-yellow-500 Hero">DigiCollect</span> {" "}
              {/* <Link to="/register" className="text-green-500 hover:underline">
                Texa, LLC
              </Link>{" "} */}
              <em className="not-italic Hero">
             is your ultimate micro-finance management platform, revolutionizing how you manage daily business activities. 
              DigiCollect tackles challenges in the micro-finance sector,
              making your operations faster, easier, and stress-free. Enjoy seamless access around the clock, 
              empowering you to handle your business anytime, anywhere.
              </em>
            </p>
            <p className="text-lg text-gray-500 mt-5">
            <em className="not-italic Hero">
              With DigiCollect, you gain seamless access to a range of features that ensure comprehensive financial management around the clock. Whether you’re handling savings collection, managing savings, 
              or tracking loans, DigiCollect empowers you to operate your business anytime and 
              anywhere. Embrace the future of micro-finance with confidence, and let DigiCollect simplify your financial management.
              </em>
            </p>

            <div style={{ marginTop: 50}}>
              <p className="Hero" style={{ fontWeight: 'bold'}}>Important Notice:</p>
              <em className="text-lg text-gray-500 mt-5 Hero">
              This app does not facilitate actual payments. It is designed for tracking and managing your transaction activities.
              </em>
              </div>
            
          </header>

          {/* <section>
            <h2 className="text-3xl font-bold mb-5">Our Team</h2>
            <p className="text-lg text-gray-500 mb-10">
              Meet our amazing team members
            </p>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
              <article className="border rounded-md p-5">
                <div className="w-1/2 border-2 border-green-500 mx-auto rounded-full overflow-hidden mb-5">
                  <img src={Emma} alt="" />
                </div>

                <div className="text-center">
                  <h1 className="text-xl font-bold text-center mb-1">
                    Founder & CEO
                  </h1>
                  <p className="text-sm font-normal text-center mb-5">
                    (Software Engineer)
                  </p>
                  <p className="text-gray-500">Emmanuel Okyere Asare</p>
                </div>
              </article>
              <article className="border rounded-md p-5">
                <div className="w-1/2 border-2 border-green-500 mx-auto rounded-full overflow-hidden mb-5">
                  <img
                    src={Rauf}
                    alt=""
                  />
                </div>

                <div className="text-center">
                  <h1 className="text-xl font-bold text-center mb-1">
                    Co-Founder
                  </h1>
                  <p className="text-sm font-normal text-center mb-5">
                    (Software Engineer)
                  </p>

                  <p className="text-gray-500">Abdul-Rauf Alhassan</p>
                </div>
              </article>

              <article className="border rounded-md p-5">
                <div className="w-1/2 border-2 border-green-500 mx-auto rounded-full overflow-hidden mb-5">
                  <img
                    src={Caleb}
                    alt=""
                  />
                </div>

                <div className="text-center">
                  <h1 className="text-xl font-bold text-center mb-1">
                    Co-Founder
                  </h1>
                  <p className="text-sm font-normal text-center mb-5">
                    (UI/UX Engineer)
                  </p>

                  <p className="text-gray-500">Caleb Yeboah</p>
                </div>
              </article>

              <article className="border rounded-md p-5">
                <div className="w-1/2 border-2 border-green-500 mx-auto rounded-full overflow-hidden mb-5">
                  <img
                    src={kaiden}
                    alt=""
                  />
                </div>

                <div className="text-center">
                  <h1 className="text-xl font-bold text-center mb-1">
                    Co-Founder
                  </h1>
                  <p className="text-sm font-normal text-center mb-5">
                    (UI/UX Engineer)
                  </p>

                  <p className="text-gray-500">Kobiah Appiah</p>
                </div>
              </article>

              <article className="border rounded-md p-5">
                <div className="w-1/2 border-2 border-green-500 mx-auto rounded-full overflow-hidden mb-5">
                  <img
                    src={Evans}
                    alt=""
                  />
                </div>

                <div className="text-center">
                  <h1 className="text-xl font-bold text-center mb-1">
                  Marketing
                  </h1>
                  <p className="text-sm font-normal text-center mb-5">
                    (Marketing Manager)
                  </p>

                  <p className="text-gray-500">Evans Aboagye</p>
                </div>
              </article>
            </div>
          </section> */}
        </div>
      </main>
    </LandingLayout>
  );
};

export default landingAbout;
