import React, { createContext, useContext, useEffect, useState } from 'react';
import { User, onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { Modal, Button, Group } from '@mantine/core';

interface UserContextProps {
  user: User | null;
  setUser: (user: User | null) => void;
  loading: boolean;
  showModal: boolean;
  closeModal: () => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setLoading(true);

      if (currentUser) {
        setUser(currentUser);

        const timeoutDuration = 30 * 60 * 1000; // 30 minutes
        const warningDuration = 2 * 60 * 1000; // 2 minutes
        let timeoutId: NodeJS.Timeout;
        let warningId: NodeJS.Timeout;

        const resetTimeout = () => {
          clearTimeout(timeoutId);
          clearTimeout(warningId);
          // Set a timeout for warning
          warningId = setTimeout(() => {
            setShowModal(true); // Show modal warning about session expiration
          }, timeoutDuration - warningDuration);

          // Set the sign-out timeout
          timeoutId = setTimeout(() => {
            signOut(auth); // Sign out if the user does not interact
          }, timeoutDuration);
        };

        // Set the initial timeout
        resetTimeout();

        // User activity events
        const events = ['mousemove', 'keypress', 'click', 'scroll', 'touchstart', 'touchmove', 'touchend', 'keydown', 'wheel'];
        const resetTimeoutHandler = () => resetTimeout(); // Ensure correct context for resetTimeout

        events.forEach(event => window.addEventListener(event, resetTimeoutHandler));

        // Cleanup function
        return () => {
          clearTimeout(timeoutId);
          clearTimeout(warningId);
          events.forEach(event => window.removeEventListener(event, resetTimeoutHandler));
        };
      } else {
        setUser(null);
        setShowModal(false); // Hide modal if user is logged out
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth, showModal]);

  const closeModal = () => {
    setShowModal(false);
    signOut(auth); // Optionally sign out the user
  };

  return (
    <UserContext.Provider value={{ user, setUser, loading, showModal, closeModal }}>
      {children}
      <Modal opened={showModal} onClose={closeModal} title="Session Expired">
        <p>Your session has expired due to inactivity. Please log in again.</p>
        <Group justify="right" mt="md">
        <Button color='#000' onClick={closeModal}>Okay</Button>
          </Group>
      
      </Modal>
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
