import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { statistics } from "../services/core-api";
import { auth } from "../firebase";

// Define the type for transaction statistics
interface TransactionStatsProp {
  weekly: [{
    date: string;
    deposits: number;
    withdrawals: number;
    depositsTotalAmount: number;
    withdrawalsTotalAmount: number;
  }];
  monthly: [{
    month: string;
    deposits: number;
    withdrawals: number;
    depositsTotalAmount: number;
    withdrawalsTotalAmount: number;
  }];
  totalWeeklyBalance: number;
  totalMonthlyBalance: number;
}

// Define the context type
interface TransactionContextType {
  transactionStats: TransactionStatsProp;
  loading: boolean;
  error: any;
  getStatistics: () => Promise<void>;
}

const StatisticsContext = createContext<TransactionContextType | undefined>(
  undefined
);

export const StatisticsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [transactionStats, setTransactionStats] = useState<TransactionStatsProp>({
    weekly: [
      {
        date: "",
        deposits: 0,
        withdrawals: 0,
        depositsTotalAmount: 0,
        withdrawalsTotalAmount: 0
      },
    ],
    monthly: [
      {
        month: "",
        deposits: 0,
        withdrawals: 0,
        depositsTotalAmount: 0,
        withdrawalsTotalAmount: 0
      },
    ],
    totalWeeklyBalance: 0,
    totalMonthlyBalance: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  const getStatistics = async () => {
    setLoading(true)
    const email = auth.currentUser?.email;
    if (email) {
      try {
        const response = await statistics(email);
        if (response) {
          setTransactionStats(response);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getStatistics();
  }, []);

  return (
    <StatisticsContext.Provider
      value={{ transactionStats, loading, error, getStatistics }}
    >
      {children}
    </StatisticsContext.Provider>
  );
};

export const useStatistics = () => {
  const context = useContext(StatisticsContext);
  if (context === undefined) {
    throw new Error("useTransaction must be used within a TransactionProvider");
  }
  return context;
};
