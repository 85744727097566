function formatDateWithCurrentTime(dateString: string) {
    // Parse the date string
    const date = new Date(dateString);
  
    // Get the current time
    const now = new Date();
    
    // Set the hours, minutes, and seconds to the current time
    date.setHours(now.getHours());
    date.setMinutes(now.getMinutes());
    date.setSeconds(now.getSeconds());
  
    // Format the date as needed (ISO string, for example)
    return date.toISOString(); // or use other formatting methods
  }  

  export {
    formatDateWithCurrentTime
  }