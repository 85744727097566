import { useState } from 'react';
import { Tabs, Title } from '@mantine/core';
import Deposit from './deposit';
import Withdrawals from './withdrawals';
import { IconArrowsExchange, IconMoneybag } from '@tabler/icons-react';

export function Transactions() {
  const [activeTab, setActiveTab] = useState<string | null>('first');

  return (
    <Tabs value={activeTab} onChange={setActiveTab}>
      <Tabs.List>
        <Tabs.Tab value="first" fw={'bolder'}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <IconArrowsExchange style={{ marginRight: 10}} />
          <Title order={5}>Deposit</Title>
          </div>
        </Tabs.Tab>
        <Tabs.Tab value="second" fw={'bolder'}> <Title order={5}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <IconMoneybag style={{ marginRight: 10}} />
          <Title order={5}>Withdrawal</Title>
          </div>
          </Title></Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="first">
        <Deposit />
      </Tabs.Panel>
      <Tabs.Panel value="second">
        <Withdrawals />
      </Tabs.Panel>
    </Tabs>
  );
}