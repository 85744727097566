import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase'; // Ensure this path is correct
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust this import according to your project structure

// Define the context type
interface DailyTransactionsContextProps {
  dailyTransactionsCount: number; // To store the count of daily transactions
  refreshDailyTransactions: () => void; // Function to refresh the daily transaction count
}

// Create the context
const DailyTransactionsContext = createContext<DailyTransactionsContextProps | null>(null);

// Provider component
export const DailyTransactionsCountProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [dailyTransactionsCount, setDailyTransactionsCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false); // Optional: Loading state

  const readDailyTransactions = async () => {
    const user = auth.currentUser;
    if (user) {
      setLoading(true); // Start loading
      try {
        const idTokenResult = await user.getIdTokenResult();
        const claims = idTokenResult.claims;

        if (claims.admin) {
          const today = new Date();
          const startOfDay = new Date(today.setHours(0, 0, 0, 0)); // Start of the current day
          const endOfDay = new Date(today.setHours(23, 59, 59, 999)); // End of the current day
          
          const q = query(
            collection(db, 'transactions'),
            where("orgId", "==", claims.orgId),
            where("createdAt", ">=", Timestamp.fromDate(startOfDay)),
            where("createdAt", "<=", Timestamp.fromDate(endOfDay))
          );

          const querySnapshot = await getDocs(q);
          const docsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setDailyTransactionsCount(docsData.length); // Set the count of daily transactions
        } else {
          // console.log('User does not have admin privileges');
          setDailyTransactionsCount(0); // Reset count if user is not an admin
        }
      } catch (error) {
        // console.error('Error getting custom claims or reading transactions:', error);
        setDailyTransactionsCount(0); // Reset count on error
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      // console.log('No user is signed in');
      setDailyTransactionsCount(0); // Reset count if no user is signed in
    }
  };

  // Optional: Refresh daily transactions count on mount
  useEffect(() => {
    readDailyTransactions();
  }, []);

  return (
    <DailyTransactionsContext.Provider value={{ dailyTransactionsCount, refreshDailyTransactions: readDailyTransactions }}>
      {children}
    </DailyTransactionsContext.Provider>
  );
};

// Custom hook to use the DailyTransactionsContext
export const useDailyTransactionsCountContext = () => {
  const context = useContext(DailyTransactionsContext);
  if (!context) {
    throw new Error('useDailyTransactionsContext must be used within a DailyTransactionsProvider');
  }
  return context;
};
