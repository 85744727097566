import { useState, useEffect } from 'react';
import { auth } from '../firebase';

const useAdminOrgId = () => {
  const [organizationId, setOrganizationId] = useState<string>('');

  useEffect(() => {
    const currentAdminUserOrgId = ()=> {
        const user = auth.currentUser
        if (user) {
          user.getIdTokenResult()
            .then((idTokenResult) => {
              // Get custom claims
              const claims = idTokenResult.claims;
              // Access custom claims
              if (claims) {
                setOrganizationId(`${claims.orgId}`)
              } 
            })
            .catch((error) => {
              console.error('Error getting custom claims:', error);
            });
        } else {
          console.log('No user is signed in');
        }
      } 

      currentAdminUserOrgId();
  }, []);

  return organizationId;
};

export default useAdminOrgId;
