import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_ENCRPTY_SMS_KEY || ''; 

const encryptValue = (value: string): string => {
  return CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
};

const decryptValue = (ciphertext: string): string => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export {
  encryptValue,
  decryptValue
}