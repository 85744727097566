import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUser, createUserType } from "../services/core-api";
import { onAuthStateChanged } from "firebase/auth";
import { ROUTES } from "../routes/const";
import { auth } from "../firebase";
import { Button, Loader, Space, Text, TextInput, Title } from "@mantine/core";
import InputText from "../components/InputText";
import { IconArrowBack, IconArrowLeft, IconArrowLeftBar, IconBuilding, IconDialpad, IconEye, IconEyeClosed, IconId, IconIdBadge, IconLock, IconMail, IconUser } from "@tabler/icons-react";
import notify from "../utils/notify";

function SignUp (){
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const [number, setNumber] = useState("");
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState<string>('');
    const [nextDetails, setNextDetails] = useState(false)
  
    const [form, setForm] = useState<createUserType>({
      email: "",
      password: "",
      reEnterPassword: "",
      displayName: '',
      companyName: '',
      phoneNumber: formattedPhoneNumber,
      agentID: `DC${Math.floor(Math.random() * 10000)}`,
      role: 'Administrator',
    });
    
    const handleOnchange = (event: any) => {
      const { name, value } = event.target;
      setForm({ ...form, [name]: value });
    };
  
  //   useEffect(()=> {
  //     // // Restore user session from Firebase
  //     const unsubscribe = onAuthStateChanged(auth, (user) => {
  //      if (user) {
  //       navigate(ROUTES.home)
  //      } else {
  //       navigate(ROUTES.login)
  //      }
  //    });
  
  //    return () => unsubscribe();
  //  }, [])
  
   useEffect(() => {
    // Prepend +233 to the phone number
    if (number) {
      setFormattedPhoneNumber(`+233${number}`);
    } else {
      setFormattedPhoneNumber('');
    }
  }, [number]);
  
  const newUser = {
    email: form.email.trim(),
    password: form.password.trim(),
    displayName: form.displayName,
    companyName: form.companyName,
    phoneNumber: formattedPhoneNumber,
    agentID: `DC${Math.floor(Math.random() * 10000)}`,
    role: 'Administrator'
  }
  
  const handleCreateAccount = async () => {
    console.log(newUser)
    if(form.email === '' || form.displayName === '' || form.password === ''){
      notify('Provide required fields')
      return
    }
    if(form.password !== form.reEnterPassword ){
      notify('Password does not match')
      return
    }
    setIsLoading(true);
    try{
    const response = await createUser(newUser)
    notify(`${response.message} || ${response}`)
    // console.log(response)
    setForm({
      email: "",
      password: "",
      reEnterPassword: "",
      companyName: "",
      displayName: '',
      phoneNumber: formattedPhoneNumber,
      agentID: `DC${Math.floor(Math.random() * 10000)}`,
      role: 'Administrator',
    })
    setIsLoading(false);
    }catch(err){
     console.log(err)
    setIsLoading(false);
    }   
  };
const [showPassword, setShowPassword]= useState(false)

    return (
      <>

<div className="formCard">
     
 
 {nextDetails ? 
 <>
 <div style={{ marginBottom: 10}}>
     <Title mb={10} order={1} className="loginIntro">Complete Account </Title>
    <Text mb={10} className="loginSubText">Provide organizational details to finalize the onboarding process</Text>
     </div>
     
 <IconArrowLeft size={40} style={{ backgroundColor: '#dedede', marginBottom: 40, padding: 10, borderRadius: 100, cursor: 'pointer'}} onClick={()=> setNextDetails(false)} />
 <Space h={10} />
  <InputText
          iconLeft={<IconBuilding />}
          name="companyName"
          width="100%"
          type="text"
            value={form.companyName || ''}
            onChange={handleOnchange}
            placeholder="Organization name"
            height={45}
          />

           <Space h={10} />
           <InputText
          iconLeft={<IconDialpad />}
          name="phoneNumber"
          type="number"
          width="100%"
            mb={10}
            value={number}
            onChange={(e: any) => setNumber(e.target.value)}
            placeholder="Phone number"
            height={45}

          />

 <Space h={10} />
          <Button 
            h={45}
            mt={20} variant="filled" color="#000" w={"100%"} onClick={handleCreateAccount} className="primaryBtn">
            {isLoading? <Loader color="#fff" size={18} /> : "Create account"}
          </Button>
 </> : 

   <>
   <div style={{ marginBottom: 10}}>
     <Title mb={10} order={1} className="loginIntro">Create Account </Title>
    <Text mb={10} className="loginSubText">Join us and start your journey today! Make micro transaction faster and easier</Text>
     </div>
    <InputText
          iconLeft={<IconUser />}
          name="displayName"
          width="100%"
          type="text"
            value={form.displayName}
            onChange={handleOnchange}
            placeholder="Full Name"
            height={45}
          />
  <Space h={10} />
     <InputText
     iconLeft={<IconMail />}
          width="100%"
            value={form.email}
            name="email"
            onChange={handleOnchange}
            placeholder="example@email.com"
            type="text"
            height={45}
          />
          <Space h={10} />
          <InputText
     iconLeft={<IconLock />}
          width="100%"
            name="password"
            value={form.password}
            onChange={handleOnchange}
            placeholder="Password"
            type= {showPassword ? 'text': 'password'}
            height={45}
            icon={showPassword ? <IconEye style={{ cursor: 'pointer'}} onClick={()=> setShowPassword(!showPassword)}/> : <IconEyeClosed style={{ cursor: 'pointer'}} onClick={()=> setShowPassword(!showPassword)}/>}
          />
          <Space h={10} />
          <InputText
          iconLeft={<IconLock />}
           width="100%"
            name="reEnterPassword"
            value={form.reEnterPassword}
            onChange={handleOnchange}
            placeholder="Re-enter Password"
            type= {showPassword ? 'text': 'password'}
            height={45}
            // icon={showPassword ? <IconEye style={{ cursor: 'pointer'}} onClick={()=> setShowPassword(!showPassword)}/> : <IconEyeClosed style={{ cursor: 'pointer'}} onClick={()=> setShowPassword(!showPassword)}/>}
          />
                
          <Space h={10} />
          
     
          <InputText
          iconLeft={<IconId />}
          disabled={true}
               name="staffID"
               type="text"
               width="100%"
            mb={10}
            value={form.agentID}
            onChange={()=> {}}
            // setAgentID(e.target.value)
            placeholder="Agent ID"
            height={45}
          />
       
          <Button 
            h={45}
            mt={20} variant="filled" color="#000" w={"100%"} onClick={()=> setNextDetails(true)} className="primaryBtn">
             Next
          </Button>
   </>}
    
      </div>
      </>
    )
  }

  export default SignUp