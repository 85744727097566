import { Alert, Button, Title } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import React, { useState } from 'react';


const Subscription = () => {
    // const isProd = process.env.NODE_ENV === 'production'
      const isDev = process.env.NODE_ENV === 'development'

  const plans = [
    // { plan: 'Free', price: 'GHS0', period: '14 days', features: [] },
    { plan: 'Basic', price: 'GHS99.99', period: '/month', features: ['1 user', 'Basic support', '1GB storage'] },
    { plan: 'Standard', price: 'GHS149.99', period: '/month', features: ['Up to 5 users', 'Priority support', '5GB storage'] },
    { plan: 'Premium', price: '299.99', period: '/month', features: ['Unlimited users', '24/7 support', '50GB storage'] },
  ];

  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

  const handleSelectPlan = (plan: string) => {
    setSelectedPlan(plan);
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <Title order={4}>Choose Your Subscription Plan</Title>
      <Alert icon={<IconInfoCircle />} color='yellow' mt={10}>
        Unlock More Features Soon – Stay Tuned for Our Upcoming Subscription!
        </Alert>

{isDev &&
<div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '20px' }}>
        {plans.map((plan) => (
          <div
            key={plan.plan}
            style={{
              border: '1px solid #ddd',
              borderRadius: '8px',
              padding: '15px',
              width: '30%',
              textAlign: 'center',
              cursor: 'pointer',
              margin: 10
            }}
            onClick={() => handleSelectPlan(plan.plan)}
          >
            <h3>{plan.plan}</h3>
            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>{plan.price}   <span style={{ fontSize: '14px', fontWeight: 'normal' }}>{plan.period}</span></p>
          

            {/* <ul style={{ listStyleType: 'none', padding: '0' }}>
              {plan.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul> */}
            <Button
            disabled
            c={'#000'}
              style={{
                padding: '10px 15px',
               
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                marginTop: '10px',
              }}
            >
              Purchase plan
            </Button>
          </div>
        ))}
      </div>}
      

    </div>
  );
};

export default Subscription;
