import React, { useEffect, useState } from "react";
import { getDoc, getDocs, collection } from "firebase/firestore";
import { db, auth } from "../firebase";
import { Badge, Loader, Pagination, Paper, Table, TextInput, Title, rem } from "@mantine/core";
import { approve_customer, ApproveCustomer, create_Customer, createUser, delete_customer, deleteCustomerProp, getAgents, getCustomers, getPendingCustomers } from "../services/core-api";
import { getDomainFromEmail } from "../utils/getUserDomain";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Button } from "@mantine/core";
import InputText from "../components/InputText";
import { formatCurrency } from "../utils/currencyFormat";
import {
  IconTrash,
  IconEdit,
  IconSettings,
  IconUsers,
  IconTransfer,
  IconRefresh
} from "@tabler/icons-react";
import {ApproveModal, DeleteModal} from "../components/DeleteModal";
// import useAdminStatus from "../hooks/useAdminStatus";
import { Tabs } from '@mantine/core';
import { formatDate } from "../utils/formatDate";
import notify from "../utils/notify";
import { useCustomerApprovalContext } from "../context/customerApprovalContext";
import { useUser } from "../context/UserContext";


interface customerProp{
  fullName: string
  depositAmount: string
   accountNumber: string
   phone: string
   accountBalance: string
    agentEmail: string
    address: string
    DOB: string
    gender: string
     agentName: string 
     agentID: string
     date: string
     approved: boolean
}

export default function CustomerApproval() {
  // const [customer, setCustomers] = useState<customerProp[]>([]);
  const [search, setSearch] = useState("");
  const [isloading, setIsloading] = useState(false);
 const {user} = useUser()
  const [ApproveOpen, setApproveOpen] = useState(false)
  const [processApprove, setProcessApprove]= useState(false)
  const [accountToApprove, setAccountToApprove] = useState('')
  const [activeTab, setActiveTab] = useState<string | null>('first')
  const { customers, setCustomers, loading, refreshCustomerApprovals} = useCustomerApprovalContext()


    function openApproveModal(accountNumber: string){
        setApproveOpen(true)
      setAccountToApprove(accountNumber)
    }

  
    const ApproveCustomer = async (accountNumber: string) => {
        setProcessApprove(true)
      const approveCustomerData: ApproveCustomer = {
        email: user?.email || '',
        accountNumber: accountNumber
      }
      try{
        const response  = await approve_customer(approveCustomerData)
        notify(response.message)
        refreshCustomerApprovals()
        setApproveOpen(false)
        setProcessApprove(false)
      }catch(err){
      console.log(err)
      setApproveOpen(false)
      setProcessApprove(false)
      }
      return
    }

  const rows = customers?.map((customer, index) => (
    <>
   

    <Table.Tr key={index}>
      <Table.Td>{customer.accountNumber}</Table.Td>
      <Table.Td>{formatDate(customer.date)}</Table.Td>
      <Table.Td>{customer.fullName}</Table.Td>
      <Table.Td>{customer.address}</Table.Td>
      <Table.Td>{customer.DOB}</Table.Td>
      <Table.Td>{customer.gender}</Table.Td>
      <Table.Td>{customer.phone}</Table.Td>
      <Table.Td>{`${formatCurrency(parseInt(customer.accountBalance), 'GHS')}`}</Table.Td>
      <Table.Td>{customer.agentName}</Table.Td>
      <Table.Td>{customer.agentEmail}</Table.Td>
      <Table.Td>{customer.agentID}</Table.Td>
      <Table.Td><Badge color={customer.approved !== true ? 'yellow': 'green'}>{customer.approved !== true ? 'Pending': 'Approved'}</Badge> </Table.Td>

      <Table.Td>
      {/* <IconEdit  size={20} /> */}
        <Button size="xs" variant="solid" bg="green" style={{ cursor: 'pointer'}} onClick={()=> openApproveModal(customer.accountNumber)}> Approve </Button>
        </Table.Td>
    </Table.Tr>
    </>
  ));



  const filterData = () => {
    const filtered = customers?.filter((client) =>
      client.fullName.includes(search)
    );
    setCustomers(filtered);
  };

  const [activePage, setActivePage] = useState(1);

  return (
    <div>
        <ApproveModal title={ <Title order={5}>Approve Customer</Title>} isLoading={processApprove} proceed={()=> ApproveCustomer(accountToApprove)} onClose={()=> setApproveOpen(false)}
      opened={ApproveOpen} content={`Are you sure you want to approve account ${accountToApprove}`} />
 
 <Paper p={20}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div>
          <Title order={4} mt={20}> Customers </Title>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <InputText
          width="30%"
          value={search}
          type="text"
          name="search"
          onChange={(e: any) => setSearch(e.target.value)}
          placeholder="Enter customer name"
        />
        <Button
        className="removeOnMobile"
          variant="default"
          color="#274941"
          onClick={filterData}
          style={{ marginInline: 10 }}
        >
          {" "}
          Filter by customer
        </Button>
        <Button
          disabled={loading}
          loading={loading}
          leftSection={<IconRefresh size={16} />}
          ml={5}
          className=""
            variant=""
            color="#000"
            onClick={() => refreshCustomerApprovals()}
          >
            Refresh
          </Button>
      </div>
      
      <div className="tableOnMobile">
      <Table stripedColor='#f2f2f2' highlightOnHover highlightOnHoverColor='#f4f3ef' striped mt={30}>
        <Table.Thead style={{ backgroundColor: '#fafafa'}}>
          <Table.Tr>
          <Table.Th style={{ fontWeight: 'bold'}}>Account No.</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Created</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}> Name</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Address</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>DOB</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Gender</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Phone</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Account Balance</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Agent Name</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Agent Email</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Agent ID</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Status</Table.Th>
            <Table.Th style={{ fontWeight: 'bold'}}>Action</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', margin: 20 }}>
      <Pagination 
      color="green"
        value={activePage}
        onChange={setActivePage}
        total={Math.ceil(customers.length / 3)} />
        </div>
        </Paper>
    </div>
    
  );
}
