import React, { useState } from "react";
import { useDailyTotalBalanceContext } from "../context/TotalDailyTransactionAmountContext";
import { Badge, Paper, Select, Title, ComboboxItem, Button, Loader } from "@mantine/core";
import { BarChart, DonutChart } from "@mantine/charts";
import { formatCurrency } from "../utils/currencyFormat";
// import { useWeeklyMetricsContext } from "../context/weeklyTransactionMetricsContext";
import { useOrgInfo } from "../context/orgContext";
import { useStatistics } from "../context/reportContext";
import { IconRefresh } from "@tabler/icons-react";


export default function Report() {
  const { dailyTotal } = useDailyTotalBalanceContext();
//   const weeklyMetricsData = useWeeklyMetricsContext()
  const {sms} = useOrgInfo()
  const {transactionStats, loading, getStatistics} = useStatistics()
  const [value, setValue] = useState<string | null>('Weekly');

  return (
    <div>
       <div style={{ display: 'flex', justifyContent: 'space-between'}}>
       <Title order={3}>Reports</Title>
       <Button disabled={loading} color="#000" onClick={getStatistics}> {loading ? <Loader color="#000" size={16}/> : (<>
        <IconRefresh size={16}style={{ marginRight: 5}}/>Refresh</>)}</Button>
       </div>
      <div style={{ display: "flex", justifyContent: "space-between", flexWrap: 'wrap' }}>
        <Paper m={10} withBorder style={{ flexGrow: 1, justifyContent: 'space-between', display: 'flex', flexWrap: 'wrap'}} p={20} >
         <div>
         <Title order={5} >Total Transactions Amount</Title>
         <div>
         <Badge size="lg"  color="green.6" mt={20}>Daily:  {formatCurrency(dailyTotal.total_daily_transactions, 'GHS')}</Badge>
         </div>
         <div>
         <Badge size="lg" mt={5}  color="orange.6">Weekly {formatCurrency(transactionStats?.totalWeeklyBalance || 0, 'GHS')}</Badge>
         </div>
         <div>
         <Badge size="lg"  mt={5} color="blue.6">Monthly { formatCurrency(transactionStats?.totalMonthlyBalance || 0, 'GHS')}</Badge>
         </div>
         
        
         </div>
          <DonutChart
          withLabels
          withLabelsLine
            data={[
              {
                name: "Daily",
                value: dailyTotal.total_daily_transactions,
                color: "green.6",
              },
              {
                name: "Weekly",
                value: transactionStats?.totalWeeklyBalance || 0,
                color: "orange.6",
              },
              {
                name: "Monthly",
                value: transactionStats?.totalMonthlyBalance || 0,
                color: "blue.6",
              },
            ]}
          />
        </Paper>


        <Paper m={10} withBorder style={{ flexGrow: 1, justifyContent: 'space-between', display: 'flex', flexWrap: 'wrap'}} p={20} >
         <div>
         <Title order={5}>SMS Usage</Title>
         <div> <Badge color="blue.6" mt={20}>Purchased: {sms.purchased}</Badge></div>
         <div>  <Badge color="green.6"> Balance: {sms.balance}</Badge></div>
         <div>  <Badge color="orange.6"> Used: {sms.purchased - sms.balance}</Badge></div>

         </div>
          <DonutChart
          withLabels
          withLabelsLine
            data={[
              {
                name: "SMS Used",
                value: sms.purchased - sms.balance,
                color: "orange.6",
              },
              {
                name: "SMS purchase",
                value: sms.purchased,
                color: "blue.6",
              },
              {
                name: "SMS balance",
                value: sms.balance,
                color: "green.6",
              },
            ]}
          />
        </Paper>
       
      </div>

      <div>
      <Paper m={10} withBorder style={{ flexGrow: 1 }} p={20}>

        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
           <div>
           <Title order={4}> { value === 'Weekly' ? 'Weekly Transactions' : 'Monthly Transactions'}</Title>
           <Title mt={5} fw={'normal'} order={5}>Total Amount: { value === 'Weekly' ? formatCurrency(transactionStats?.totalWeeklyBalance || 0, 'GHS') :  formatCurrency(transactionStats?.totalMonthlyBalance || 0, 'GHS')  }</Title>
           </div>
           <Select
           placeholder="Select Period"
           value={value}
          onChange={setValue}
          data={['Weekly', 'Monthly']}
    />
        </div>
            
          
        <BarChart
        withLegend
      h={350}
 data={value === 'Weekly' ? transactionStats?.weekly || [] : transactionStats?.monthly || []}
      dataKey= { value === 'Weekly'? "date" : 'month'}
      type="stacked"
      xAxisLabel="Date"
      yAxisLabel="Amount"
      series={[
        { name: 'depositsTotalAmount', label: 'Total Deposit Amount GHS', color: 'blue.6' }, 
        { name: 'withdrawalsTotalAmount', label: 'Total Withdrawal Amount GHS', color: 'red.6' }, 
        { name: 'deposits', label:'Deposit Count', color: 'green.6' },
        { name: 'withdrawals', label:'Withdrawals Counts', color: 'orange.6' },
      ]}
    />
        </Paper>
      </div>
    </div>
  );
}
