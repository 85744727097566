import React, { useEffect, useState } from "react";
import { getDoc, getDocs, collection } from "firebase/firestore";
import { db, auth } from "../firebase";
import {
  Badge,
  Select,
  Table,
  TextInput,
  Title,
  Divider,
  Pagination,
  Paper,
} from "@mantine/core";
import { isToday, parseISO } from "date-fns";
import {
  create_Customer,
  createCustomerProp,
  delete_customer,
  deleteCustomerProp,
  // getAgents,
  // getCustomers,
  handleExport,
  uploadCustomerData,
} from "../services/core-api";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Button } from "@mantine/core";
import InputText from "../components/InputText";
import { formatCurrency } from "../utils/currencyFormat";
import {
  IconTrash,
  IconEdit,
  IconFileExport,
  IconFileExcel,
  IconFileUpload,
  IconInfoCircle,
  IconSearch,
  IconPlus,
  IconInfoSmall,
  IconCancel,
  IconRefresh,
} from "@tabler/icons-react";
import { DeleteModal } from "../components/DeleteModal";
import { formatDate } from "../utils/formatDate";
import { generateAccountNumber } from "../utils/generateAccountNo";
import { ToastContainer } from "react-toastify";
import notify from "../utils/notify";
import { useOrgInfo } from "../context/orgContext";
import { useCustomerDataContext } from "../context/customersContext";

interface customerProp {
  fullName: string;
  loanAmount: number;
  interestRate: string;
  depositAmount: string;
  accountNumber: string;
  phone: string;
  accountBalance: string;
  agentEmail: string;
  address: string;
  DOB: string;
  gender: string;
  agentName: string;
  agentID: string;
  date: string;
  approved: boolean;
  savingsType: string;
  nationalId: string;
  createdAt: string;
  updatedAt?: string
  updatedBy?: string
  approvedBy?: string
}

export default function Customers() {
  // const [customer, setCustomers] = useState<customerProp[]>([]);
  // const [customersAddedToday, setCustomersAddedToday] = useState<customerProp[]>([]);
  const [tag, setTag] = useState<string | null>("");

  const [search, setSearch] = useState("");
  const [isloading, setIsloading] = useState(false);
  const userEmail = auth.currentUser?.email || "";
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [processDelete, setProcessDelete] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState("");
  const [opened, { open, close }] = useDisclosure(false);
  const [file, setFile] = useState(null);
  const [openUpload, setOpenUpload] = useState(false);
  const [fileName, setFileName] = useState("");
  const [savingsType, setSavingsType] = useState<string | null>("");
  const [gender, setGender] = useState<string | null>("");
  const { companyName, generateAccNo, customerAccountTypes } = useOrgInfo();
  const [updateCustomerState, setUpdateCustomerState] = useState(false);
  const {
    customers,
    customersAddedToday,
    loading,
    setCustomers,
    refreshCustomers,
  } = useCustomerDataContext();

  const [form, setForm] = useState({
    fullName: "",
    interestRate: "",
    loanAmount: 0,
    depositAmount: "",
    phone: "",
    accountNumber: "",
    agentEmail: userEmail,
    address: "",
    DOB: "",
    gender: gender,
    nationalId: "",
    savingsType: savingsType,
  });

  const initialFormState = {
    fullName: "",
    interestRate: "",
    loanAmount: 0,
    depositAmount: "",
    phone: "",
    accountNumber: "",
    agentEmail: userEmail,
    address: "",
    DOB: "",
    gender: gender,
    nationalId: "",
    savingsType: savingsType,
  };

  const handleOnchange = (event: any) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  // const getCustomersData = async () => {
  //   const email = auth.currentUser?.email;
  //   if (email) {
  //     const customers = await getCustomers(email);
  //     setCustomers(customers);
  //      // Filter customers created today
  //      const todayCustomers = customers?.filter((client: any) => client.date === new Date().toDateString())
  //      setCustomersAddedToday(todayCustomers)
  //   }
  //   return;
  // };

  const getCustomClaims = async (): Promise<string> => {
    const user = auth.currentUser;
    if (user) {
      const idTokenResult = await user.getIdTokenResult(true);
      const customClaims = idTokenResult.claims;
      return `${customClaims.agentId}`;
    }
    return "";
  };

  const createCustomer = async () => {
    if (
      form.fullName === "" ||
      form.DOB === "" ||
      form.address === "" ||
      form.agentEmail === "" ||
      form.depositAmount === "" ||
      form.nationalId === "" ||
      form.phone === "" ||
      !savingsType ||
      !gender
    ) {
      return;
    }
    setIsloading(true);
    try {
      const newAccountNumber = {
        branchCode: "001",
        accountTypeCode: `0${Math.floor(Math.random() * 100)}`,
        lastSequentialNumber: Math.floor(Math.random() * 9999),
        institutionCode: companyName.slice(0, 2).toUpperCase(),
      };

      const newCustomer: createCustomerProp = {
        fullName: tag !== "" ? form.fullName + " " + tag : form.fullName,
        loanAmount: form.loanAmount,
        interestRate: form.interestRate,
        depositAmount: form.depositAmount,
        accountNumber: generateAccNo
          ? generateAccountNumber(newAccountNumber)
          : form.accountNumber,
        phone: form.phone,
        accountBalance: form.depositAmount,
        agentEmail: auth.currentUser?.email || "",
        address: form.address,
        DOB: form.DOB,
        gender: gender || "",
        agentName: auth.currentUser?.displayName || "",
        agentID: await getCustomClaims(),
        nationalId: form.nationalId,
        savingsType: savingsType || "",
      };

      const response = await create_Customer(newCustomer);
      if (response) {
        setIsloading(false);
        refreshCustomers();
        close();
        notify(response);
        setForm(initialFormState);
      }
    } catch (err) {
      // console.log(err);
      setIsloading(false);
    }
  };

  const updateCustomerData = async () => {
    if (
      form.fullName === "" ||
      form.DOB === "" ||
      form.address === "" ||
      form.agentEmail === "" ||
      form.depositAmount === "" ||
      form.nationalId === "" ||
      form.phone === "" ||
      !savingsType ||
      !gender
    ) {
      return;
    }

    setIsloading(true);
    try {
      const updateCustomer: createCustomerProp = {
        fullName: form.fullName,
        loanAmount: form.loanAmount,
        interestRate: form.interestRate,
        depositAmount: form.depositAmount,
        accountNumber: form.accountNumber,
        phone: form.phone,
        accountBalance: form.depositAmount,
        agentEmail: auth.currentUser?.email || "",
        address: form.address,
        DOB: form.DOB,
        gender: gender || "",
        agentName: auth.currentUser?.displayName || "",
        agentID: await getCustomClaims(),
        nationalId: form.nationalId,
        savingsType: savingsType || "",
      };

      const response = await create_Customer(updateCustomer);
      if (response) {
        setIsloading(false);
        refreshCustomers();
        close();
        notify(response);
        setForm(initialFormState);
      }
    } catch (err) {
      console.log(err);
      setIsloading(false);
    }
  };

  function openDeleteModal(accountNumber: string) {
    setDeleteOpen(true);
    setAccountToDelete(accountNumber);
  }

  const deleteCustomer = async (accountNumber: string) => {
    setProcessDelete(true);
    const deleteCustomerData: deleteCustomerProp = {
      agentEmail: userEmail,
      accountNumber: accountNumber,
    };
    try {
      const response = await delete_customer(deleteCustomerData);
      notify(response.message);
      refreshCustomers();
      setDeleteOpen(false);
      setProcessDelete(false);
    } catch (err) {
      console.log(err);
      setDeleteOpen(false);
      setProcessDelete(false);
    }
    return;
  };

  const updateCustomer = (customer: any) => {
    setSavingsType(customer.savingsType);
    setGender(customer.gender);
    setUpdateCustomerState(true);
    const udpateCustomerData = {
      fullName: customer.fullName,
      loanAmount: customer.loanAmount,
      interestRate: customer.interestRate,
      depositAmount: customer.depositAmount,
      accountNumber: customer.accountNumber,
      phone: customer.phone,
      agentEmail: userEmail,
      address: customer.address,
      DOB: customer.DOB,
      gender: gender,
      savingsType: savingsType,
      nationalId: customer.nationalId,
    };
    setForm(udpateCustomerData);
    open();
  };

  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRow = (index: any) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const itemsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total balance
  const totalBalance =
    customers?.reduce((acc, customer) => acc + parseFloat(customer.accountBalance), 0)
  const formattedTotalBalance = formatCurrency(totalBalance, "GHS");

  // Calculate total pages
  const totalPages = Math.ceil(customers?.length / itemsPerPage);

  // Calculate the rows to display for the current page


  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredData = customers.filter((client) =>
    client.accountNumber.toLowerCase().includes(search.toLowerCase()) || client.fullName.toLowerCase().includes(search.toLowerCase()) )

  const rows = filteredData.slice(startIndex, endIndex);
 
 


  const data = rows.map((customer: customerProp, index) => (
    <React.Fragment key={index}>
      <Table.Tr style={{ cursor: "pointer", backgroundColor: customer.updatedAt ? '#fff9ee' : '' }} onClick={() => toggleRow(index)}>
        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <IconPlus size={16} />
        </Table.Td>
        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {customer.nationalId}
        </Table.Td>
        <Table.Td
          style={{
            backgroundColor:
              customer.date === new Date().toDateString() ? "#c5ebc5" : "",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {customer.fullName}
        </Table.Td>
        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {customer.phone}
        </Table.Td>
        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {customer.accountNumber}
        </Table.Td>
        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: parseFloat(customer.accountBalance) >= 20 ? "green" : "red",
            fontWeight: "bolder",
          }}
        >
          {`${formatCurrency(parseFloat(customer.accountBalance), "GHS")}`}
        </Table.Td>
        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {customer.savingsType === "Loan" ? (
            <Badge size="sm" color="red">
              Loan
            </Badge>
          ) : (
            <Badge size="sm">{customer.savingsType}</Badge>
          )}
        </Table.Td>
        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Badge
            size="sm"
            color={customer.approved !== true ? "yellow" : "green"}
          >
            {customer.approved !== true ? "Pending" : "Approved"}
          </Badge>
        </Table.Td>
        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "flex",
          }}
        >
          <IconEdit
            style={{ cursor: "pointer", marginRight: 5 }}
            onClick={() => updateCustomer(customer)}
            size={20}
          />
          <IconTrash
            style={{ cursor: "pointer" }}
            onClick={() => openDeleteModal(customer.accountNumber)}
            size={20}
            color="red"
          />
        </Table.Td>
      </Table.Tr>

      {/* Collapsible details row */}
      {expandedRow === index && (
        <Table.Tr>
          <Table.Td colSpan={14} p={20} style={{ backgroundColor: '#fff'}}>
            <div>
              <Title order={5} mb={10}>
                Details:
              </Title>
              <div style={{ display: "flex", textAlign: "left" }}>
                <p>
                  <strong>Address:</strong>
                </p>{" "}
                <p style={{ marginLeft: 5 }}>{customer.address}</p>
              </div>
              <div style={{ display: "flex", textAlign: "left" }}>
                <p>
                  <strong>Date of birth:</strong>
                </p>{" "}
                <p style={{ marginLeft: 5 }}>{customer.DOB}</p>
              </div>
              <div style={{ display: "flex", textAlign: "left" }}>
                <p>
                  <strong>Gender:</strong>
                </p>{" "}
                <p style={{ marginLeft: 5 }}>{customer.gender}</p>
              </div>
              <div style={{ display: "flex", textAlign: "left" }}>
                <p>
                  <strong>Agent Name:</strong>
                </p>{" "}
                <p style={{ marginLeft: 5 }}>{customer.agentName}</p>
              </div>
              <div style={{ display: "flex", textAlign: "left" }}>
                <p>
                  <strong>Agent Email:</strong>
                </p>{" "}
                <p style={{ marginLeft: 5 }}>{customer.agentEmail}</p>
              </div>
              <div style={{ display: "flex", textAlign: "left" }}>
                <p>
                  <strong>Agent ID:</strong>
                </p>{" "}
                <p style={{ marginLeft: 5 }}>{customer.agentID}</p>
              </div>
              <div style={{ display: "flex", textAlign: "left" }}>
                <p>
                  <strong>Created At:</strong>
                </p>{" "}
                <p style={{ marginLeft: 5 }}>
                  {formatDate(customer.createdAt)}
                </p>
              </div>
              {customer.approvedBy ? <div style={{ display: "flex", textAlign: "left" }}>
                <p>
                  <strong>Approved By:</strong>
                </p>{" "}
                <p style={{ marginLeft: 5 }}>
                  {customer.approvedBy}
                </p>
              </div> : null}
           
              
              {customer.updatedAt ? 
              <>
               <div style={{ display: "flex", textAlign: "left" }}>
                <p>
                  <strong>Updated At:</strong>
                </p>{" "}
                <p style={{ marginLeft: 5 }}>
                  {formatDate(customer.updatedAt)}
                </p>
              </div> 
              
              <div style={{ display: "flex", textAlign: "left" }}>
                <p>
                  <strong>Edited By:</strong>
                </p>{" "}
                <p style={{ marginLeft: 5 }}>
                  {customer.updatedBy || 'Not Available'}
                </p>
              </div>
              </>
              
              : null}
              

            </div>
          </Table.Td>
        </Table.Tr>
      )}
    </React.Fragment>
  ));

  // useEffect(() => {
  //   // refreshCustomers();
  // }, [form]);

  function openModal() {
    open();
  }

  const downloadData = () => {
    if (customers.length <= 0) {
      notify("No data to export");
      return;
    }
    setIsloading(true);
    handleExport(customers)
      .then(() => {
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  };
  const handleFileChange = (e: any) => {
    setFile(e.currentTarget.files[0]);
    setFileName(e.currentTarget.files[0].name);
  };

  const uploadData = () => {
    setIsloading(true);
    const customersFile = {
      file: file,
      email: auth.currentUser?.email ? auth.currentUser.email : "",
    };
    uploadCustomerData(customersFile)
      .then((response: any) => {
        if (response.status === 200) {
          notify("Customer data uploaded successfully");
          setOpenUpload(false);
          refreshCustomers();
        } else {
          notify("Error uploading data");
          setOpenUpload(false);
        }
        setIsloading(false);
      })
      .catch((err: any) => {
        setIsloading(false);
        return err;
      });
  };

  return (
    <>
      <Modal
        opened={openUpload}
        onClose={() => setOpenUpload(false)}
        title="Upload Customers Data"
        centered
        p={30}
      >
        {/* Modal content */}
        <input
          accept=".xlsx, .xls"
          type="file"
          id="uploadFile"
          style={{ visibility: "hidden" }}
          onChange={(e) => handleFileChange(e)}
        />
        <label
          htmlFor="uploadFile"
          style={{
            backgroundColor: "#eeeeee",
            display: "flex",
            borderRadius: 10,
            justifyContent: "center",
            padding: 60,
            border: "3px dotted #dedede",
          }}
        >
          {fileName ? fileName : <IconFileUpload size={70} />}
        </label>
        <Button
          disabled={isloading}
          onClick={uploadData}
          w={"100%"}
          color={"#274941"}
          mt={10}
        >
          {" "}
          {isloading ? "uploading..." : "Upload file"}
        </Button>
      </Modal>
      <DeleteModal
        title={<Title order={5}>Delete Customer</Title>}
        isLoading={processDelete}
        proceed={() => deleteCustomer(accountToDelete)}
        onClose={() => setDeleteOpen(false)}
        opened={deleteOpen}
        content={`Are you sure you want to delete account ${accountToDelete}`}
      />

      <Modal
        size={"lg"}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        opened={opened}
        onClose={close}
        title={
          <Title ml={10} order={5}>
            {" "}
            {updateCustomerState ? "Update customer" : "Create Customer"}
          </Title>
        }
        centered
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          {!generateAccNo && (
            <TextInput
              error={form.accountNumber === ""}
              w={"45%"}
              label="Account Number"
              mb={10}
              value={form.accountNumber}
              name="accountNumber"
              onChange={handleOnchange}
              placeholder="Account Number"
            />
          )}
          <Divider mb={20} />
        </div>

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <TextInput
            error={form.fullName === ""}
            w={"45%"}
            label="Full Name"
            mb={10}
            value={tag !== "" ? form.fullName + " " + tag : form.fullName}
            name="fullName"
            onChange={handleOnchange}
            placeholder="Full Name"
          />

          <TextInput
            error={form.DOB === ""}
            type="date"
            w={"45%"}
            label="Date of Birth"
            mb={10}
            value={form.DOB}
            name="DOB"
            onChange={handleOnchange}
            placeholder="date of Birth"
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Select
            error={gender === ""}
            placeholder="Gender"
            label="Gender"
            w={"45%"}
            defaultValue="Male"
            data={["Male", "Female"]}
            value={gender}
            onChange={setGender}
          />

          <TextInput
            disabled={updateCustomerState}
            error={form.depositAmount === ""}
            w={"45%"}
            label="Initial Deposit amount"
            mb={10}
            type="number"
            value={form.depositAmount}
            onChange={handleOnchange}
            name="depositAmount"
            placeholder="Deposit Amount"
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <TextInput
            error={form.phone === ""}
            w={"45%"}
            label="Phone number"
            mb={10}
            value={form.phone}
            name="phone"
            type="number"
            onChange={handleOnchange}
            placeholder="Phone number"
          />

          <TextInput
            error={form.address === ""}
            w={"45%"}
            label="Address"
            mb={10}
            value={form.address}
            name="address"
            onChange={handleOnchange}
            placeholder="Address"
          />
        </div>

        <hr style={{ margin: 30 }} />

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Select
            error={savingsType === ""}
            placeholder="Package"
            label="Account Type"
            w={"45%"}
            defaultValue="Monthly package"
            data={customerAccountTypes}
            value={savingsType}
            onChange={setSavingsType}
          />

          <TextInput
            error={form.nationalId === ""}
            w={"45%"}
            label="National ID"
            mb={10}
            value={form.nationalId}
            name="nationalId"
            onChange={handleOnchange}
            placeholder="National ID"
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {savingsType === "Loan" && (
            <>
              <TextInput
                error={""}
                disabled={updateCustomerState}
                placeholder="Loan Amount"
                label="Loan Amount"
                type="number"
                w={"45%"}
                name="loanAmount"
                value={form.loanAmount}
                onChange={handleOnchange}
              />

              <TextInput
                disabled={updateCustomerState}
                error={""}
                w={"45%"}
                label="Interest Rate"
                mb={10}
                value={form.interestRate}
                name="interestRate"
                onChange={handleOnchange}
                placeholder="0%"
              />
            </>
          )}
        </div>

        <hr style={{ margin: 30 }} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            margin: 10,
          }}
        >
          <small>
            Name tags attached to customer name make it easier to identify a
            customer account type when making transactions (💰 represent loan
            and 2️⃣ represents customer second account ){" "}
            <span style={{ fontWeight: "bolder" }}>
              Click on 🚫 to remove tag on customer name
            </span>
          </small>
          <Select
            w={"60%"}
            leftSection={
              <IconCancel
                style={{ color: "red", cursor: "pointer" }}
                size={16}
                onClick={() => setTag("")}
              />
            }
            value={tag}
            onChange={setTag}
            label="Choose name tag"
            ml={10}
            data={["💰", "2️⃣"]}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 20,
          }}
        >
          <Button
            w={"45%"}
            variant="outline"
            color="#000"
            onClick={() => close()}
          >
            Cancel
          </Button>

          {updateCustomerState ? (
            <Button
             loading={isloading}
             disabled={isloading}
              w={"45%"}
              variant="filled"
              color="#000"
              onClick={() => updateCustomerData()}
            >
              {isloading ? "Please wait..." : "Update customer"}
            </Button>
          ) : (
            <Button
            disabled={isloading}
            loading={isloading}
              w={"45%"}
              variant="filled"
              color="#000"
              onClick={() => createCustomer()}
            >
              {isloading ? "Please wait..." : "Save"}
            </Button>
          )}
        </div>
      </Modal>

      <Paper
      withBorder
      p={20}

      >
        <div  style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: 'wrap',
        }}>
           <div>
          <Title order={3}> Customers </Title>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Title mr={10}>{customersAddedToday.length}</Title>
            <small>Added today</small>
            <div
              style={{
                backgroundColor: "#c5ebc5",
                width: 20,
                height: 20,
                marginLeft: 20,
                marginRight: 5,
              }}
            ></div>
            <small> New customer</small>

            <div
              style={{
                backgroundColor: "orange",
                width: 20,
                height: 20,
                marginLeft: 20,
                marginRight: 5,
              }}
            ></div>
            <small> Edited Customer</small>
          </div>
        </div>
    
         <div>
         <Title mb={5} order={5} style={{ fontWeight: 'normaL'}}> Total Balances</Title>
         <Title mb={10} order={2}> {formattedTotalBalance}</Title>
         <small>Total customer balances</small>
         </div>

        </div>
       

         <div>
         <small style={{ color: "red", display: 'flex', alignItems: 'center' }}>
          {" "}
         <IconInfoCircle style={{ marginRight: 5}} /> Customer data upload should follow exact table column structure
        </small>
         </div>
   
    
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginTop: 20,
        }}
      >
        <div>
          <Button
            disabled={isloading}
            variant="outline"
            mr={10}
            color="#274941"
            onClick={downloadData}
          >
            {/* onClick={openModal} */}
            <IconFileExcel /> &nbsp; Export Data
          </Button>
          <Button
            className="removeOnMobile"
            variant=""
            color="#000"
            onClick={() => setOpenUpload(true)}
          >
            Import Data
          </Button>

          <Button
            disabled={loading}
            leftSection={<IconRefresh size={16} />}
            ml={5}
            className="removeOnMobile"
            variant=""
            color="#000"
            onClick={() => refreshCustomers()}
          >
            Refresh
          </Button>
        </div>

        <div style={{ display: "flex" }}>

           <TextInput
               leftSection={<IconSearch size={16} />}
                 miw={350}
                 mr={10}
                  placeholder="Search by account number or customer name"
                  value={search}
                  onChange={(e) => setSearch(e.currentTarget.value)}
                  size="sm"
                />
      

          {/* <Button
            className="removeOnMobile"
            variant="default"
            color="#000"
            onClick={filterData}
            style={{ marginInline: 10, width: "100%" }}
          >
            {" "}
            Filter by customer
          </Button> */}

          <Button
            style={{ width: "100%" }}
            variant="filled"
            color="#000"
            onClick={() => {
              openModal();
              setUpdateCustomerState(false);
              setForm(initialFormState);
            }}
          >
            Create account
          </Button>
        </div>
      </div>

      <div style={{ overflowX: "auto", overflowY: "auto", maxHeight: "100%" }}>
        <Table
         stripedColor='#f2f2f2' highlightOnHoverColor='#f4f3ef'
          highlightOnHover
          stickyHeader
          stickyHeaderOffset={10}
          striped
          mt={20}
        >
          <Table.Thead  style={{ backgroundColor: "#fafafa" }}>
            <Table.Tr>
              <Table.Th>
                <IconInfoSmall />
              </Table.Th>
              <Table.Th style={{ fontWeight: 'bold'}}>National ID</Table.Th>
              <Table.Th style={{ fontWeight: 'bold'}}>Name</Table.Th>
              <Table.Th style={{ fontWeight: 'bold'}}>Phone</Table.Th>
              <Table.Th style={{ fontWeight: 'bold'}}>Acc No.</Table.Th>
              <Table.Th style={{ fontWeight: 'bold'}}>Acc Balance</Table.Th>
              <Table.Th style={{ fontWeight: 'bold'}}>Acc Type</Table.Th>
              <Table.Th style={{ fontWeight: 'bold'}}>Status</Table.Th>
              <Table.Th style={{ fontWeight: 'bold'}}>Action</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{data}</Table.Tbody>
        </Table>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 10,
            marginBottom: 20,
          }}
        >
          <small style={{ fontWeight: "bold" }}>
            Page {currentPage} of {totalPages}
          </small>
          <Pagination
            color="#000"
            size="sm"
            total={totalPages}
            value={currentPage}
            onChange={(newPage) => setCurrentPage(newPage)}
          />
        </div>
      </div>
      </Paper>
    </>
  );
}
