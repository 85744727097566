import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase'; // Ensure this path is correct
import { getPendingCustomers } from '../services/core-api'; // Adjust import path as needed

interface customerProp{
    fullName: string
    depositAmount: string
     accountNumber: string
     phone: string
     accountBalance: string
      agentEmail: string
      address: string
      DOB: string
      gender: string
       agentName: string 
       agentID: string
       date: string
       approved: boolean
  }
  
// Define the context type
interface CustomerApprovalContextProps {
    customers: customerProp[];
    setCustomers: React.Dispatch<React.SetStateAction<customerProp[]>>;
    loading: boolean; // Loading state
    refreshCustomerApprovals: () => void; // Function to refresh pending customers
}

// Create the context
const CustomerApprovalContext = createContext<CustomerApprovalContextProps | null>(null);

// Provider component
export const CustomerApprovalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [customers, setCustomers] = useState<customerProp[]>([]);
    const [loading, setLoading] = useState<boolean>(false); // Loading state

    const getCustomerApprovalData = async () => {
        const email = auth.currentUser?.email;
        if (email) {
            setLoading(true); // Start loading
            try {
                const customersData = await getPendingCustomers(email);
                setCustomers(customersData);
            } catch (error) {
                console.error('Error fetching pending customers:', error);
                // Handle errors accordingly, e.g., set empty state
                setCustomers([]);
            } finally {
                setLoading(false); // Stop loading
            }
        } else {
            // console.log('No user is signed in');
            setCustomers([]);
        }
    };

    // Fetch pending customers data on mount
    useEffect(() => {
        getCustomerApprovalData();
    }, []);

    return (
        <CustomerApprovalContext.Provider value={{ customers, setCustomers, loading, refreshCustomerApprovals: getCustomerApprovalData }}>
            {children}
        </CustomerApprovalContext.Provider>
    );
};

// Custom hook to use the CustomerApprovalContext
export const useCustomerApprovalContext = () => {
    const context = useContext(CustomerApprovalContext);
    if (!context) {
        throw new Error('useCustomerApprovalContext must be used within a CustomerApprovalProvider');
    }
    return context;
};
