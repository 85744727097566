import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';

// Define a context type for Admin status
interface AdminStatusContextType {
  isAdmin: boolean;
}

// Create the context with an initial value of null
const AdminStatusContext = createContext<AdminStatusContextType | null>(null);

// Provider component
export const AdminStatusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        try {
          const idTokenResult = await currentUser.getIdTokenResult();
          setIsAdmin(!!idTokenResult.claims.admin);
        } catch (error) {
          console.error('Error fetching custom claims:', error);
        }
      }
    };

    checkAdminStatus();
  }, []);

  return (
    <AdminStatusContext.Provider value={{ isAdmin }}>
      {children}
    </AdminStatusContext.Provider>
  );
};

// Custom hook to consume the admin status context
export const useAdminStatusContext = () => {
  const context = useContext(AdminStatusContext);
  if (!context) {
    throw new Error('useAdminStatusContext must be used within an AdminStatusProvider');
  }
  return context;
};
