import { useState, useEffect } from 'react';
import { auth } from '../firebase';

const useCollaboratorStatus = () => {
  const [isCollaborator, setIsCollaborator] = useState(false);

  useEffect(() => {
    const checkCollaboratorStatus = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        try {
          const idTokenResult = await currentUser.getIdTokenResult();
          if(idTokenResult.claims.role === 'Collaborator'){
            setIsCollaborator(!!idTokenResult.claims.role);
          }
        } catch (error) {
          console.error('Error fetching custom claims:', error);
        }
      }
    };

    checkCollaboratorStatus();
  }, []);

  return isCollaborator;
};

export default useCollaboratorStatus;
