import React, { useEffect, useState } from "react";
import { getDoc, getDocs, collection } from "firebase/firestore";
import { db, auth } from "../firebase";
import { Badge, Pagination, Table, TextInput, Title, rem } from "@mantine/core";
import { approve_customer, ApproveCustomer, create_Customer, createUser, delete_customer, deleteCustomerProp, getAgents, getCustomers, getPendingCustomers } from "../services/core-api";
import { getDomainFromEmail } from "../utils/getUserDomain";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Button } from "@mantine/core";
import InputText from "../components/InputText";
import { formatCurrency } from "../utils/currencyFormat";
import {
  IconTrash,
  IconEdit,
  IconSettings,
  IconUsers,
  IconTransfer
} from "@tabler/icons-react";
import { Tabs } from '@mantine/core';
import CustomerApproval from "./customerApprovals";
import WithdrawalApproval from "./withdrawalApproval";
// import useAdminStatus from "../hooks/useAdminStatus";
import { useAdminStatusContext } from "../context/adminContext";


interface customerProp{
  fullName: string
  depositAmount: string
   accountNumber: string
   phone: string
   accountBalance: string
    agentEmail: string
    address: string
    DOB: string
    gender: string
     agentName: string 
     agentID: string
     date: string
     approved: boolean
}

export default function Approvals() {
  const [activeTab, setActiveTab] = useState<string | null>('first')
  const { isAdmin } = useAdminStatusContext()

  return (
    <div>

      <Tabs value={activeTab} onChange={setActiveTab}>
      <Tabs.List>
        <Tabs.Tab leftSection={<IconUsers style={{ width: rem(18), height: rem(18) }} />} value="first" style={{fontSize: 16, fontWeight:'bold'}}><Title order={5}>Customer</Title></Tabs.Tab>
        {isAdmin ? <Tabs.Tab leftSection={<IconTransfer style={{ width: rem(18), height: rem(18) }} />} value="second" style={{fontSize: 16, fontWeight:'bold'}}><Title order={5}>Withdrawal</Title></Tabs.Tab> : null }
      </Tabs.List>

      <Tabs.Panel value="first">
        <CustomerApproval />
      </Tabs.Panel>

      <Tabs.Panel value="second">
       <WithdrawalApproval />
      </Tabs.Panel>
    </Tabs>

  
    </div>
  );
}
