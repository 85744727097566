interface genAccountType {
    branchCode: string
     accountTypeCode: string
      lastSequentialNumber: number
      institutionCode: string
}

function generateAccountNumber({branchCode, accountTypeCode, lastSequentialNumber, institutionCode}: genAccountType) {
    const sequentialNumber = String(lastSequentialNumber + 1).padStart(6, '0'); // Increment and pad the sequential number
  
    return `${institutionCode}-${branchCode}-${accountTypeCode}-${sequentialNumber}`;
  }
  
export {
    generateAccountNumber
}
  