import React, {  useEffect } from 'react'
import { useUser } from '../context/UserContext'
import { Navigate } from 'react-router-dom'
import { ROUTES } from './const'

export default function ProtectedRoute({children}: any) {
  const { user } = useUser()

  if(!user?.uid){
    return <Navigate to={ROUTES.login} />
  }
  if(user.emailVerified !== true) {
    return <Navigate to={ROUTES.verifyEmail} />
  }
  return <>{children}</>
}
